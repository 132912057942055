import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";

import "react-toastify/dist/ReactToastify.css";
import img from "../../images/noImg.png";
import { Pagination } from "antd";
import NotificationModal from "../notification/notificationModal";
import { DatePicker, Space } from "antd";
import moment from 'moment'


const axiosApiInstance = axios.create();

const ViewClients = (props) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ID, setID] = useState("");
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [keyword, setKey] = useState("");
  const [selectedBranch, setBranch] = useState("");
  const [branches, setBranches] = useState([]);
  const [date, setDate] = useState("");
  const getClients = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/customer_list`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result.data.data);
      setData(result.data.data);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getClients();
    getBranches();
  }, []);

  const showNotiModal = (id) => {
    setShowModal(true);
    console.log(id);
    setID(id);
  };

  const hideModal = () => {
    setShowModal(false);
  };

  const onChange = async (page) => {
    console.log(page);
    setCurrent(page);
    let result = await axiosApiInstance.get(
      APILINK + `/customer_list?page=${page}`
    );
    setData(result.data.data);
    setCount(result.data.count);
  };
  const handleSearch = async (e) => {
    setKey(e.target.value);
  };
  const getBranches = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/branch_create`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result);
      setBranches(result.data.data);
    }
  };
  const handleDate = (date, dateString) => {
    setDate(dateString);
  };
  const search = async () => {
    console.log(date)
    let result = await axiosApiInstance.get(
      APILINK +
      `/customer_list?name=${keyword}&date=${date === undefined ? "" : date}&branch=${selectedBranch}`
    );
    if (result) {
      setData(result.data.data);
    }
  };
  const clientDetails = (e)=>{
    props.history.push(`/client/${e.target.id}`)
  }
  const clearSearch = () => {
    setBranch("")
    setKey("")
    setDate()
    getClients()

  }
  return (
    <div className="page-header">
      <Container className="clients">
        <Row>
          <Col className="align-left" md={4} sm={12}>
            <h2>
              {" "}
              <i className="fas fa-user pr-2"></i>Clients
            </h2>
          </Col>
          <Col md={8} sm={12}>
            <Row>
              <Col sm="12" md="3">
                {" "}
                <input
                  value={keyword}
                  onChange={handleSearch}
                  placeholder="Write text"
                  className="searchInput"
                />
              </Col>
              <Col sm="12" md="3">
                {" "}
                <select
                  onChange={(e) => setBranch(e.target.value)}
                  value={selectedBranch}
                  className="clientsSelect"
                >
                  {branches.map((branch) => {
                    return (
                      <>
                        {" "}
                        <option selected hidden>
                          Select Branch
                        </option>{" "}
                        <option value={branch.id}>{branch.title}</option>
                      </>
                    );
                  })}
                </select>
              </Col>
              <Col sm="12" md="3">
                {" "}
                <DatePicker onChange={handleDate} />
              </Col>
              <Col className="text-left" sm={12} md={3}>
                <button onClick={search} className="btn-card">
                  Search
                </button>
                <button onClick={clearSearch} className="btn-card">
                  Clear
                </button>
              </Col>
            </Row>
          </Col>
        </Row>

        <Row>
          {data.length !== 0 ? (
            data.map((client) => {
              return (
                <Col lg={4} md={6} sm={12}>
                  <div className="Card">
                    <Row>
                      <Col lg={6}>
                        {client.image !== null ? (

                          <img
                            className="client-image"
                            src={APILINK + client.image}
                          />
                        ) : (
                          <img className="client-image" src={img} />
                        )
                        }
                      </Col>
                      <Col lg={6}>
                        <p className="title"><strong>{client.user.full_name}</strong></p> 
                        <p>{client.user.email}</p>
                        <p>{client.user.mobile}</p>
                      </Col>
                     
                    </Row>
                    <Row>
                    <Col lg={12}>
                        <button
                          onClick={() => showNotiModal(client.user.id)}
                          className="btn-card"
                        >
                          Notify
                        </button>
                        <button id={client.id} onClick={clientDetails} className="btn-card">Details</button>
                      </Col>
                    </Row>
                    


                  </div>




                </Col>
              );
            })
          ) : (
            <h4 className="text">No Clients Yet</h4>
          )}
        </Row>
        <Row>
          <Col className="align-left" lg={6}>
            {data.length !== 0 ? (
              <Pagination
                onChange={onChange}
                defaultCurrent={current}
                total={count}
                current={current}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
      <NotificationModal
        id={ID}
        show={showModal}
        hide={() => setShowModal(false)}
      />
    </div>
  );
};

export default ErrorHandler(ViewClients, axiosApiInstance);
