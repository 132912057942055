import { ProSidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import "react-pro-sidebar/dist/css/styles.css";
import axios from "axios";
import React, { useState } from "react";
import { connect } from "react-redux";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { NavLink } from "react-router-dom";

const axiosApiInstance = axios.create();

export const SideMenu = (props) => {
  const [toggled, setToggled] = useState(false);

  const toggleSideMenu = () => {
    setToggled(!toggled);
  };

  return (
    <React.Fragment>
      <ProSidebar toggled={toggled} onToggle={toggleSideMenu} className="md">
        {props.user.user_type == "4" ? (
          <Menu iconShape="square">
            <MenuItem active>
              <NavLink to="/home" exact activeClassName="navitem-active">
                <i class="fas fa-phone-alt pr-2"></i> Clients Requests{" "}
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/trainers" exact activeClassName="navitem-active">
                <i class="fas fa-user-ninja"></i> Trainers
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/clients" exact activeClassName="navitem-active">
                <i class="fas fa-user"></i> Clients
              </NavLink>
            </MenuItem>

            <MenuItem>
              <NavLink to="/branches" exact activeClassName="navitem-active">
                <i class="fas fa-code-branch pr-2"></i> Branches{" "}
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/sessions" exact activeClassName="navitem-active">
                <i class="fas fa-calendar-alt pr-2"></i> Sessions{" "}
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                to="/announcements"
                exact
                activeClassName="navitem-active"
              >
                <i class="fas fa-bullhorn pr-2"></i> Announcements{" "}
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/promo_codes" exact activeClassName="navitem-active">
                <i class="fas fa-tags pr-2"></i> Promo Codes{" "}
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/articles" exact activeClassName="navitem-active">
                <i class="fas fa-newspaper pr-2"></i> Articles{" "}
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                to="/operations"
                exact
                activeClassName="navitem-active"
              >
                <i class="fas fa-user"></i> Opertaions
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink
                to="/payments"
                exact
                activeClassName="navitem-active"
              >
               <i class="fas fa-money-bill-alt pr-2"></i> Payments
              </NavLink>
            </MenuItem>
            {/* <SubMenu title="Components">
              <MenuItem>Component 1</MenuItem>
              <MenuItem>Component 2</MenuItem>
            </SubMenu> */}
          </Menu>
        ) : props.user.user_type == "3" ? (
          <Menu iconShape="square">
            <MenuItem>
              <NavLink to="/clients" exact activeClassName="navitem-active">
                <i class="fas fa-user"></i> Clients
              </NavLink>
            </MenuItem>
            <MenuItem>
              <NavLink to="/home" exact activeClassName="navitem-active">
                <i class="fas fa-user"></i> Clients Requests
              </NavLink>
            </MenuItem>

           
          </Menu>
        ) : null}
      </ProSidebar>
      <div onClick={toggleSideMenu} className="btn-toggle">
        {" "}
        <svg
          stroke="currentColor"
          fill="currentColor"
          stroke-width="0"
          viewBox="0 0 448 512"
          height="1em"
          width="1em"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path d="M16 132h416c8.837 0 16-7.163 16-16V76c0-8.837-7.163-16-16-16H16C7.163 60 0 67.163 0 76v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16zm0 160h416c8.837 0 16-7.163 16-16v-40c0-8.837-7.163-16-16-16H16c-8.837 0-16 7.163-16 16v40c0 8.837 7.163 16 16 16z"></path>
        </svg>{" "}
      </div>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});
export default connect(mapStateToProps)(
  ErrorHandler(SideMenu, axiosApiInstance)
);
