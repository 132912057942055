import React, { useEffect, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Modal, Button } from "react-bootstrap";
import { Pagination } from "antd";

import "react-toastify/dist/ReactToastify.css";

const axiosApiInstance = axios.create();

const Packages = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [showWarning, setWarning] = useState(false);
  const [packageID, setPackageID] = useState([]);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const getPackages = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/package?branch_id=${props.match.params.id}`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result);
      setData(result.data.data);
      setCount(result.data.count);

    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPackages();
  }, []);

  const deletePackage = async () => {
    let result = await axiosApiInstance.delete(
      APILINK + `/package_details/${packageID}`
    );
    if (result.data == "") {
      setWarning(false);
      getPackages();
    }
  };
  const show = (e) => {
    setWarning(true);
    setPackageID(e.target.id);
  };
  const onChange = async (page) => {
    console.log(page);
    setCurrent(page);
    let result = await axiosApiInstance.get(
      APILINK + `/package?branch_id=${props.match.params.id}&page=${page}`
    );
    setData(result.data.data);
    setCount(result.data.count);
  };
  return (
    <div className="page-header">
      <Container className="">
        <Row>
          <Col className="align-left" lg={12}>
            <h2>
              {" "}
              <i class="fas fa-home"></i>Packages
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="align-right" lg={12}>
            <button
              className="btn-add"
              onClick={() => {
                props.history.push(`/add_package/${props.match.params.id}`);
              }}
            >
              Add New Package
            </button>
          </Col>
        </Row>
        <Row>
          {data.length !== 0 ? (
            data.map((x) => {
              return (
                <Col lg={4} md={6} sm={12}>
                  <div className="Card">
                    <i class="fas card-home fa-home"></i>
                    <p>{x.title}</p>
                    <p>
                      <strong>Type : </strong>
                      {x.package_type === "1"
                        ? "Revolt Live"
                        : x.package_type === "2"
                        ? "Group Training"
                        : "Gym"}
                    </p>
                    <p>
                      <strong>Expiration : </strong>
                      {x.expiration_type === "1" ? "Monthly" : "Annually"}
                    </p>
                    <p>
                      <strong>Session Limit : </strong>
                      {x.session_limit}
                    </p>
                    <p>
                      <strong>Price : </strong>
                      {x.price}
                    </p>
                    <p>
                      <i
                        onClick={() => {
                          props.history.push(`/edit_package/${x.id}`);
                        }}
                        className="fas fa-edit pr-3"
                      ></i>
                      <i onClick={show} id={x.id} className="fas fa-trash"></i>
                    </p>
                  </div>
                </Col>
              );
            })
          ) : (
            <h4 className="text">No Packages Yet</h4>
          )}
        </Row>
        <Row>
          <Col className="align-left" lg={6}>
            {data.length !== 0 ? (
              <Pagination
                onChange={onChange}
                defaultCurrent={current}
                total={count}
                current={current}
              />
            ) : null}
          </Col>
        </Row>
        <Modal
          show={showWarning}
          onHide={() => {
            setWarning(false);
          }}
        >
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Package?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button onClick={deletePackage} className="headBtn mx-3">
                    Delete
                  </Button>
                  <Button
                    onClick={() => {
                      setWarning(false);
                    }}
                    className="headBtn"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
      </Container>
    </div>
  );
};

export default ErrorHandler(Packages, axiosApiInstance);
