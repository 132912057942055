import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";

import "react-toastify/dist/ReactToastify.css";
import img from "../../images/noImg.png";
import { Empty, Pagination } from "antd";
import NotificationModal from "../notification/notificationModal";
import { DatePicker, Space } from "antd";
import { Tabs } from "antd";
const { TabPane } = Tabs;
const axiosApiInstance = axios.create();

const Client = (props) => {
  const [data, setData] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [ID, setID] = useState("");
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [keyword, setKey] = useState("");
  const [selectedBranch, setBranch] = useState("");
  const [client, setClient] = useState("");

  const getClients = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/customer_list`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result.data.data);
      const client = result.data.data.filter(
        (client) => client.id === props.match.params.id
      );
      setClient(client[0]);
      console.log(client[0]);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getClients();
  }, []);

  return (
    <div className="page-header">
      <Container className="clients">
        <Row className="mb-4">
          <Col className="align-left" md={12} sm={12}>
            <h2>
              {" "}
              <i className="fas fa-user pr-2"></i>
              {client !== "" ? client.user.full_name : null} Subscriptions
            </h2>
          </Col>
        </Row>
        {client !== "" ? (
          <Row>
            <Col sm="12">
              <Tabs defaultActiveKey="1">
                <TabPane tab="Packages" key="1">
                  {client.packages.map((info) => {
                    return (
                      <Row>
                        <Col sm="12" md="4">
                          <div className="Card">
                            <h6>{info.title}</h6>
                            <ul
                              style={{ textAlign: "left", marginTop: "15px" }}
                            >
                              <li>
                                <strong>Type : </strong>
                                {info.package_type === "1"
                                  ? "Revolt Live"
                                  : info.package_type === "2"
                                  ? "Group Training"
                                  : "Gym"}
                              </li>
                              <li>
                                <strong>limit : </strong>
                                {info.session_limit}{" "}
                              </li>
                              <li>
                                <strong>Expiration Type : </strong>
                                {info.expiration_type === "1"
                                  ? "Monthly"
                                  : "Annually"}
                              </li>
                            </ul>
                            <h6>{info.price}</h6>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                  {client.packages.length == 0 ? <Empty /> : null}
                </TabPane>
                <TabPane tab="Sessions" key="2">
                  {client.sessions.map((info) => {
                    return (
                      <Row>
                        <Col sm="12" md="4">
                          <div className="Card">
                            <h6>{info.title}</h6>
                            <ul
                              style={{ textAlign: "left", marginTop: "15px" }}
                            >
                              <li>
                                <strong>Type : </strong>
                                {info.session_type === "1"
                                  ? "Revolt Live"
                                  : info.session_type === "2"
                                  ? "Group Training"
                                  : null}
                              </li>

                              <li>
                                <strong>Expiration Type : </strong>
                                {info.expiration_type === "1"
                                  ? "Monthly"
                                  : "Annually"}
                              </li>
                              <li>
                                <strong>Start Date : </strong>
                                {info.start_date.date}{" "}
                              </li>
                              <li>
                                <strong>Start Time : </strong>
                                {info.start_time}{" "}
                              </li>
                            </ul>
                            <h6>{info.price}</h6>
                          </div>
                        </Col>
                      </Row>
                    );
                  })}
                  {client.sessions.length == 0 ? <Empty /> : null}
                </TabPane>
              </Tabs>
            </Col>
          </Row>
        ) : (
          <Empty />
        )}
      </Container>
    </div>
  );
};

export default ErrorHandler(Client, axiosApiInstance);
