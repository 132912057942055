import React, { useEffect, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Modal } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { Empty, Spin, Pagination, Checkbox, Button, Input } from "antd";
import { ToastContainer, toast } from "react-toastify";

const axiosApiInstance = axios.create();

const ClientRequests = () => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [show, setShow] = useState(false);
  const [price, setPrice] = useState("");
  const [duration, setDuration] = useState("");
  const [notificationID, setNotificationID] = useState("");
  const [checkID, setCheckID] = useState("");
  const [isChecked, setChecked] = useState(false);
  const [showError, setShowError] = useState(false);
  const [link, setLink] = useState("");
  const [selectedOption, setOption] = useState("1");
  const [durationError , setDurationError] = useState(false)

  useEffect(() => {
    try {
      const getRequests = async () => {
        let result;
        if (selectedOption == "1") {
          result = await axiosApiInstance
            .get(APILINK + `/employee_notfication?page=${current}`)
            .catch((err) => {
              console.log(err);
            });
        } else {
          result = await axiosApiInstance
            .get(APILINK + `/program_notfication?page=${current}`)
            .catch((err) => {
              console.log(err);
            });
        }
        if (result) {
          console.log(result);
          setData(result.data.data);
          setLoading(false);
          setCount(result.data.count);
        }
      };
      getRequests();
    } catch (err) {
      console.log(err);
    }
  }, [selectedOption]);
  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
  }, []);

  const onChange = async (page) => {
    console.log(page);
    setCurrent(page);

    let result;
    if (selectedOption == "1") {
      result = await axiosApiInstance
        .get(APILINK + `/employee_notfication?page=${page}`)
        .catch((err) => {
          console.log(err);
        });
    } else {
      result = await axiosApiInstance
        .get(APILINK + `/program_notfication?page=${page}`)
        .catch((err) => {
          console.log(err);
        });
    }

    if (result) {
      console.log(result);
      setData(result.data.data);
      setLoading(false);
      setCount(result.data.count);
    }
  };

  const handleCalled = async (e) => {
    console.log(e.target);
    setChecked(e.target.checked);
    let data = {
      is_called: e.target.checked,
    };
    let result;
    if (selectedOption === "1") {
      result = await axiosApiInstance
        .put(APILINK + `/employee_notfication/${e.target.id}`, data)
        .catch((err) => {
          console.log(err);
        });
    } else {
      result = await axiosApiInstance
        .put(APILINK + `/program_notfication/${e.target.id}`, data)
        .catch((err) => {
          console.log(err);
        });
    }
    console.log(result);
    if (result) {
      setCheckID(e.target.id);
    }
  };

  const send = async () => {
    let data = {
      price: price,
      notification_id: notificationID,
      duration: duration,
    };
    console.log(data);
    let result;
    if (selectedOption == "1") {
      result = await axiosApiInstance
        .post(APILINK + `/pay_personal_trainer/`, data)
        .catch((err) => {
          console.log(err.respone);
        });
    } else {
      result = await axiosApiInstance
        .post(APILINK + `/pay_program`, data)
        .catch((err) => {
          console.log(err.respone);
        });
    }

    if (result) {
      console.log(result);
      setShow(false);
      if (result.data.data.sms_status == 400) {
        setShowError(true);
        setLink(result.data.data.payment_link);
      } else {
        setTimeout(() => {
          toast.info("Message has been sent successfully");
        }, 500);
      }
    }
  };
  return (
    <div className="page-header">
      <Container className="">
        <Row>
          <Col className="align-left" lg={8} md="12">
            <h2>
              {" "}
              <i class="fas fa-phone pr-2"></i> Clients Requests
            </h2>
          </Col>
          <Col className="align-left" lg={4} md="12">
            <select
              onChange={(e) => {
                setOption(e.target.value)
                setCurrent("1")
              }}
              value={selectedOption}
            >
              <option value="1">Personal Trainer</option>
              <option value="2">Online Programms</option>
            </select>
          </Col>
        </Row>
        <Row>
          {data.length !== 0 && loading == false ? (
            data.map((data) => {
              return (
                <Col sm="12" md="6" lg="4">
                  <div className="Card">
                    <Row className="mb-4">
                      <Col sm="12">
                        {selectedOption == "1" ? (
                          data.type
                            .replace(/[\[\]']/g, "")
                            .split(",")
                            .map((type) => {
                              return <span className="tags">{type}</span>;
                            })
                        ) : (
                          <span className="tags">
                            {data.type == "1" ? "1-on-1" : "Custom"}
                          </span>
                        )}
                        <p style={{marginTop : "20px"}}><strong>Date : </strong>{data.timestamp.split("T")[0]}</p>
                      </Col>
                    </Row>
                    <Row style={{width : "100%"}}>
                      <Col sm="12" md="6">
                        <h6>Trainee</h6>
                        <p><strong>
                          {" "}
                          {data.client.full_name} {data.client.last_name}
                        </strong>
                        </p>
                        <p>{data.client.mobile}</p>
                      </Col>
                      <Col sm="12" md="6">
                        {" "}
                        <h6>Trainer</h6>
                        <p>
                          <strong>
                            {data.trainer?.full_name} {data.trainer?.last_name}
                          </strong>
                        </p>
                        <p>{data.trainer?.mobile}</p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm={6}>
                        <Checkbox checked={data.is_called} id={data.id} onChange={handleCalled}>
                          Called?
                        </Checkbox>
                      </Col>
                      <Col sm="6">
                        {checkID == data.id && isChecked ? (
                          <Button
                            id={data.id}
                            className="requestsBtn"
                            onClick={() => {
                              setShow(true);
                              setNotificationID(data.id);
                            }}
                          >
                            Set Price
                          </Button>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })
          ) : loading ? (
            <Spin tip="...loading" />
          ) : (
            <Empty />
          )}
        </Row>
        <Row>
          <Col className="align-left" lg={6}>
            {data.length === 0 ? null : (
              <Pagination
                onChange={onChange}
                defaultCurrent={current}
                total={count}
                current={current}
              />
            )}
          </Col>
        </Row>
        <Modal
          className="text-center requestsModel"
          show={show}
        // onHide={this.hideAdd}
        >
          <Container className="py-4">
            <Row>
              <Col sm="6">
                <label className="label">Price</label>
                <Input
                  value={price}
                  type="number"
                  required
                  onChange={(e) => {
                    setPrice(e.target.value);
                  }}
                  placeholder="price"
                />
              </Col>
              <Col sm="6">
                <label className="label">Duration in month</label>

                <Input
                  value={duration}
                  max="12"
                  type="number"
                  required
                  onChange={(e) => {
                    if (parseInt(e.target.value) > 12) {
                      setDurationError(true)
                      console.log(e.target.value)
                      setDuration(12);
                    } else {
                      setDurationError(false)
                      setDuration(e.target.value);
                    }


                  }}
                  placeholder="Duration"
                />
                {durationError ? 
                <p className="error">Value can't be more than 12</p>
                  : null
                }
              </Col>
            </Row>
            <Row className="mt-4">
              <Col sm="12">
                {" "}
                <Button onClick={send} className="requestsBtn mr-2">
                  Send
                </Button>
                <Button
                  onClick={() => {
                    setShow(false);
                  }}
                  className="requestsBtn"
                >
                  Cancel
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>

        <Modal
          className="text-center requestsModel p-3"
          show={showError}
          onHide={() => {
            setShowError(false);
          }}
        // onHide={this.hideAdd}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <p style={{ fontSize: "18px", color: "white" }}>
                  Failed to send message{" "}
                </p>
              </Col>
              <Col sm="12">
                {" "}
                <p style={{ overflowX: "scroll", color: "white" }}>
                  {" "}
                  Payment link : {link}
                </p>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <Button
                  onClick={() => {
                    setShowError(false);
                  }}
                  className="requestsBtn"
                >
                  Done
                </Button>
              </Col>
            </Row>
          </Container>
        </Modal>
        <ToastContainer position="bottom-right" />
      </Container>
    </div>
  );
};

export default ErrorHandler(ClientRequests, axiosApiInstance);
