import React, { useEffect, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import "react-toastify/dist/ReactToastify.css";
import { Empty, Spin, Pagination, Switch, Checkbox } from "antd";
const axiosApiInstance = axios.create();

const AdminClientRequests = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [count, setCount] = useState(1);
  const [current, setCurrent] = useState(1);
  const [program, setProgram] = useState("");
  const [checked, setChecked] = useState(false);
  const [filter, setFilter] = useState("1");

  useEffect(() => {
    window.scrollTo(0, 0);
    setLoading(true);
    try {
      const getRequests = async () => {
        let result;
        if (filter === "1") {
          result = await axiosApiInstance
            .get(APILINK + `/customers_programs`)
            .catch((err) => {
              console.log(err);
            });
        } else {
          result = await axiosApiInstance
            .get(APILINK + `/customers_personals`)
            .catch((err) => {
              console.log(err);
            });
        }
        if (result) {
          console.log(result);
          setData(result.data.data);
          setLoading(false);
          setCount(result.data.count);
        }
      };
      getRequests();
    } catch (err) {
      console.log(err);
    }
  }, [filter]);

  const onChange = (page) => {
    console.log(page);
    setCurrent(page);
    const req = async () => {
      let result;
      if (filter === "1") {
        result = await axiosApiInstance
          .get(APILINK + `/customers_programs&page=${page}`)
          .catch((err) => {
            console.log(err);
          });
      } else {
        result = await axiosApiInstance
          .get(APILINK + `/customers_personals&page=${page}`)
          .catch((err) => {
            console.log(err);
          });
      }
      if (result) {
        console.log(result);
        setData(result.data.data);
        setLoading(false);
        setCount(result.data.count);
      }
    };
  };

  //   const clicked = (checked, e) => {
  //       console.log(checked)
  //       console.log(e)
  //     setProgram(e.target.id)
  //     setChecked(checked)
  //   };
  const onActivate = async (e) => {
    let result = await axiosApiInstance.get(
      APILINK + `/freeze_program/${e.target.id}?freeze=${e.target.checked}`
    );
    if (result) {
      console.log("status changed");
    }
  };

  return (
    <div className="page-header">
      <Container className="">
        <Row>
          <Col className="align-left" lg={12}>
            <h2>
              {" "}
              <i class="fas fa-phone-square-alt"></i>Clients Requests
            </h2>
          </Col>
          <Col lg={4} sm={12} className="align-right">
            <select onChange={(e) => setFilter(e.target.value)}>
              <option value="1">Online Programs</option>
              <option value="2">Personal Trainer</option>
            </select>
          </Col>
        </Row>
        <Row>
          {data.length !== 0 && loading == false ? (
            data.map((data) => {
              return (
                <Col sm="12" md="6">
                  <div className="branch-card">
                    <Row className="">
                      <Col sm="12">
                        {filter === "1" ? (
                          <span className="tags">
                            {data.notification.type === "1"
                              ? "1-on-1"
                              : "Custom"}
                          </span>
                        ) : (
                          <span>
                            {data.notification.type
                              .replace(/[\[\]']/g, "")
                              .split(",")
                              .map((type) => {
                                return <span className="tags">{type}</span>;
                              })}
                          </span>
                        )}
                        ;
                        <p className="mt-4">
                          <strong>Price : </strong>
                          {data.price}
                        </p>
                        {data.start_date ? (
                          <React.Fragment>
                            <p>
                              <strong>Start : </strong>
                              {data.start_date
                                ? data.start_date.split("T")[0]
                                : null}{" "}
                            </p>
                            
                          </React.Fragment>
                        ) : (
                          <p>
                            <strong>Remainanig Days : </strong>
                            {data.remaining_days}
                          </p>
                        )}
                        <p>
                          {data.notification.is_nutrition ? "Nutrition " : null}
                        </p>
                      </Col>
                    </Row>
                    <Row>
                      <Col sm="12" md="6">
                        {data.notification.client !== null ? (
                          <>
                            <h6>Trainee</h6>
                            <p>
                              {" "}
                              {data.notification.client.full_name}{" "}
                              {data.notification.client.last_name}
                            </p>
                            <p>{data.notification.client.mobile}</p>
                          </>
                        ) : (
                          <p>No data</p>
                        )}
                      </Col>
                      <Col sm="12" md="6">
                        {" "}
                        <h6>Trainer</h6>
                        {data.notification.trainer !== null ? (
                          <>
                            <p>
                              {data.notification.trainer.full_name}{" "}
                              {data.notification.trainer.last_name}
                            </p>
                            <p>{data.notification.trainer.mobile}</p>
                          </>
                        ) : (
                          <p>No data</p>
                        )}
                      </Col>
                      <Col lg={12}>
                        {data.freeze !== undefined ? (
                          <div className="freeze">
                            <Checkbox
                              id={data.id}
                              defaultChecked={data.freeze}
                              onChange={onActivate}
                            >
                              Freeze?
                            </Checkbox>
                          </div>
                        ) : null}
                      </Col>
                    </Row>
                  </div>
                </Col>
              );
            })
          ) : (
            <Col sm="12" className="text-center mt-5">
              <Empty />
            </Col>
          )}
        </Row>
        <Row>
          <Col className="align-left" lg={6}>
            {data.length !== 0 ? (
              <Pagination
                onChange={onChange}
                defaultCurrent={current}
                total={count}
                current={current}
              />
            ) : null}
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ErrorHandler(AdminClientRequests, axiosApiInstance);
