import React, { Component } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Select, Checkbox, DatePicker } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/leads.css";
import moment from "moment";

const axiosApiInstance = axios.create();

class EditCode extends Component {
  state = {
    code: "",
    discount: "",
    type: "",
    redeem: "",
    endDate: "",
  };
  getDetails = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/promo_code/${this.props.match.params.id}`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result);
      this.setState({
        code: result.data.code,
        discount: parseInt(result.data.discount).toFixed(2),
        redeem: result.data.redeem_once,
        endDate: result.data.end_date,
      });
      if (result.data.discount_type == true) {
        this.setState({ type: "1" });
      } else {
        this.setState({ type: "2" });
      }
    }
  };
  componentDidMount() {
    this.getDetails();
  }

  edit = async () => {
    let data;
    if (this.state.type == "1") {
      data = {
        code: this.state.code,
        discount: this.state.discount,
        discount_type: true,
        redeem_once: this.state.redeem,
        end_date: this.state.endDate,
      };
    } else {
      data = {
        code: this.state.code,
        discount: this.state.discount,
        discount_type: false,
        redeem_once: this.state.redeem,
        end_date: this.state.endDate,
      };
    }

    console.log(data);
    let result = await axiosApiInstance.put(
      APILINK + `/promo_code/${this.props.match.params.id}`,
      data
    );
    console.log(result);
    if (result) {
      if (result.data.id) {
        setTimeout(() => {
          toast.info("Promo code has been updated successfully");
        }, 500);
        setTimeout(() => {
          this.props.history.push(`/promo_codes`);
        }, 2000);
      }
    }
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleType = (v, e) => {
    console.log(e.value);
    this.setState({ type: e.value });
  };
  handleRedeem = (e) => {
    this.setState({ redeem: e.target.checked });
  };
  handleDate = (v1, v2) => {
    this.setState({ endDate: v2 });
  };
  render() {
    return (
      <div className="page-header">
        <Container>
          <Form id="infoForm">
            <Row>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>code</Form.Label>
                  <Form.Control
                    name="code"
                    value={this.state.code}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="code"
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Discount</Form.Label>
                  <Form.Control
                    name="discount"
                    value={this.state.discount}
                    onChange={this.handleChange}
                    type="number"
                    placeholder="Discount"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Discount Type</Form.Label>
                  <Select
                    allowClear
                    placeholder="Discount Type"
                    value={this.state.type}
                    onChange={this.handleType}
                    style={{ width: "100%" }}
                  >
                    <Select.Option value={"1"}>Percentage</Select.Option>
                    <Select.Option value={"2"}>Money</Select.Option>
                  </Select>
                </Form.Group>
              </Col>
              <Col className="mt-5" sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Checkbox
                    checked={this.state.redeem}
                    onChange={this.handleRedeem}
                  >
                    Redeem Once?
                  </Checkbox>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>End Date</Form.Label>

                  <DatePicker
                    value={moment(this.state.endDate)}
                    className="w-100"
                    onChange={this.handleDate}
                  />
                </Form.Group>
              </Col>
              y
            </Row>

            <Row>
              {" "}
              <Col sm={12}>
                <Button onClick={this.edit} className="submit-btn-add">
                  Edit
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

export default ErrorHandler(EditCode, axiosApiInstance);
