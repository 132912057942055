import React, { useEffect, useState } from "react";
import axios from "axios";
import { APILINK } from "../EndPoint";
import ErrorHandler from "../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { Tabs, Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import "react-toastify/dist/ReactToastify.css";

const { TabPane } = Tabs;

const axiosApiInstance = axios.create();
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const Payments = (props) => {
  const [data, setData] = useState([]);
  const [personaldata, setPersonalData] = useState([]);

  const [loading, setLoading] = useState(false);

  const getPackagePayments = async () => {
    setLoading(true)
    let result = await axiosApiInstance
      .get(APILINK + `/payments_list`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      setLoading(false)
      console.log(result);
      setData(result.data.data);
    }
  };

  const getPersonalPayments = async () => {
    setLoading(true)

    let result = await axiosApiInstance
      .get(APILINK + `/customers_personals`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      setLoading(false)

      console.log(result);
      setPersonalData(result.data.data);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getPackagePayments();
  }, []);
  const getPayments = (key) => {
    if (key == "1") {
      getPackagePayments();
    } else if (key == "2") {
      getPersonalPayments();
    } else {
    }
  };
  return (
    <div className="page-header">
      <Container className="">
        <Row>
          <Col className="align-left" lg={12}>
            <h2>
              {" "}
              <i class="fas fa-money-bill-alt pr-2"></i>Payments
            </h2>
          </Col>
        </Row>

        <Tabs defaultActiveKey="1" onTabClick={getPayments}>
          <TabPane tab="Package Payments" key="1">
            {loading ? <Spin indicator={antIcon} /> : null}
            <Row>
              {data.length !== 0
                ? data.map((data) => {
                  return (
                    <Col sm="12" md="4">
                      <div className="Card height">
                        {/* {data.notification.client !== null ? ( */}
                        <>
                          <p className="tags">{data.package.package_type === "1" ? "Live" : data.package.package_type === "2" ? "Group Training" : "Gym"}</p>
                          <p>
                            <strong>Client Name : </strong>
                            {data.customer.full_name}
                          </p>{" "}
                          <p>
                            {" "}
                            <strong>Client Phone : </strong>
                            {data.customer.mobile}
                          </p>
                          <p>
                            <strong>Client Email : </strong>
                            {data.customer.email}
                          </p>
                          <p>
                            <strong>Amount Paid : </strong>
                            {parseInt(data.price).toFixed(2)}
                          </p>
                          {data.package.branch ? <p>
                            <strong>Branch Name : </strong>

                            {data.package.branch?.title}
                          </p> : null}

                          <p>
                            <strong>Package Name : </strong>
                            {data.package.title}
                          </p>
                        </>
                        {/* ) : (
                            <p>No Data</p>
                          )} */}
                      </div>
                    </Col>
                  );
                })
                : null}
            </Row>
          </TabPane>
          <TabPane tab="Personal Trainer Payments" key="2">
            {loading ? <Spin indicator={antIcon} /> : null}
            <Row>

              {personaldata.length !== 0
                ? personaldata.map((data) => {
                  return (
                    <Col sm="12" md="4">
                      <div className="Card height">
                        {data.notification.client !== null ? (
                          <>
                            <p>
                              <strong>Client Name : </strong>
                              {data.notification.client.full_name}
                            </p>{" "}
                            <p>
                              {" "}
                              <strong>Client Phone : </strong>
                              {data.notification.client.mobile}
                            </p>
                            <p>
                              <strong>Client Email : </strong>
                              {data.notification.client.email}
                            </p>
                            <p>
                              <strong>Amount Paid : </strong>
                              {data.price}
                            </p>
                            {data.notification.trainer !== null ? (
                              <p>
                                <strong>Trainer Name : </strong>
                                {data.notification.trainer.full_name}{" "}
                                {data.notification.trainer.last_name}
                              </p>
                            ) : null}
                          </>
                        ) : (
                          <p>No Data</p>
                        )}
                      </div>
                    </Col>
                  );

                })
                : null}
            </Row>

          </TabPane>
        </Tabs>
      </Container>
    </div>
  );
};

export default ErrorHandler(Payments, axiosApiInstance);
