import React, { useEffect, useState } from 'react'
import axios from 'axios'
import '../css/leads.css'
import logo from '../images/logo.svg'
import { APILINK } from '../EndPoint';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Container } from "react-bootstrap";
import { useForm } from "react-hook-form";

const Leads = (props) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    
    
    const [options, setOptions] = useState([])
    


    
    useEffect(() => {
        try {
            const getCities = async ()=>{
                let result = await axios.get(APILINK + "/get_city_file")
                setOptions(result.data)
                console.log(result.data)
            }
            getCities()
        }
        catch(err) {
            console.log(err)
        }
        
    }, []);
    
    
    const onSubmit = async (data) => {
        
        let result = await axios.post(APILINK + "/add_lead", data).catch(err => {
            console.log(err.response)
            for (const error in err.response.data) {
                setTimeout(() => {
                    toast.error(`${err.response.data[error][0]}`);
                }, 500);
            }
        })

        if (result) {
            setTimeout(() => {
                toast.success("Thank you for you time!");
            }, 500);
        }
    };
    
    return (
        <div className='page-header'>
            <Container className="page-container">
                <Row>
                    <Col lg={6} sm={12} xs={12}>
                        <div className="logo">
                            <img alt="Logo" className="mobile-logo" src={logo}></img>
                        </div>

                    </Col>
                    <Col lg={6} sm={12} xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="form">
                            <label className="label">Name</label>

                            <input className="input" {...register("username", { required: true })} />
                            <p>{errors.username?.type === 'required' && "Name is required"}</p>

                            <label className="label">Email</label>

                            <input className="input" type="email" {...register("email", { required: true })} />
                            <p>{errors.email?.type === 'required' && "Email is required"}</p>

                            <label className="label">Mobile</label>

                            <input className="input" type="number" {...register("mobile", { required: true })} />
                            <p>{errors.mobile?.type === 'required' && "Mobile is required"}</p>

                            <label className="label">Region</label>

                            <select {...register("city", { required: true })}>
                                <option selected hidden disabled>Select your region</option>
                                {options.map(option => {
                                    return <option key={option.id}>{option.city_name_en}-{option.city_name_ar}</option>
                                })}

                            </select>
                            <p>{errors.city?.type === 'required' && "Region is required"}</p>

                            <input className="submit-btn" type="submit" />

                        </form>
                    </Col>
                </Row>
            </Container>
            <ToastContainer position="bottom-right" />
        </div>
    )
}

export default Leads