import React, { useEffect, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { Pagination } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const axiosApiInstance = axios.create();

const ViewAnnouncements = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [count, setCount] = useState(1);
  const [selectedImage, setSelectedImage] = useState("");
  const [show, setShow] = useState(false);
  const [file, setFile] = useState({});
  const [fileName, setFileName] = useState("");
  const [disable, setDisabled] = useState(true);

  const onSelect = (e) => {
    console.log(e.target.id)
    setSelectedImage(e.target.id);
  };
  const getAnnouncements = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/announcement`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result.data.announcements);
      setData(result.data.announcements);
      setCount(result.data.count);
    }
  };
  const onChange = async (page) => {
    console.log(page);
    setCurrent(page);
    let result = await axiosApiInstance.get(
      APILINK + `/trainer_signup?page=${page}`
    );
    setData(result.data.data);
    setCount(result.data.count);
  };

  const onDelete = async (e) => {


    let result = await axiosApiInstance.delete(
      APILINK + `/announcement/${selectedImage}`
    );
    getAnnouncements();
    setShow(false);


  };

  const deleteSelected = () => {
    if (selectedImage === "") {
      setTimeout(() => {
        toast.error("Select an image to delete");
      }, 500);
    }
    else {
      setShow(true);
      console.log(show);
    }

  }
  const onFileChange = (e) => {
    // Update the state
    setFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
    setDisabled(false);
  };
  const onFileUpload = async () => {
    const formData = new FormData();

    formData.append("image", file, file.name);
    let result = await axiosApiInstance.post(
      APILINK + "/announcement",
      formData
    );
    console.log(result);
    if (result) {
      getAnnouncements();
    }
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getAnnouncements();
  }, []);
  return (
    <div className="page-header">
      <Container className="">
        <Row>
          <Col className="align-left" lg={12}>
            <h2>
              {" "}
              <i class="fas fa-bullhorn pr-2"></i>Announcements
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="align-right" lg={12}>
            <div className="wrapper">
              <div className="file-upload">
                <input
                  style={{ color: "white" }}
                  onChange={onFileChange}
                  type="file"
                />
                Add Announcement
              </div>
            </div>{" "}
            <p style={{ color: "white" }}>{fileName}</p>
          </Col>
        </Row>
        <Row>
          {data.length !== 0 ? (
            data.map((announcement) => {
              return (
                <Col key={announcement.id} lg={3} md={4} sm={12}>
                  <div
                    className={
                      selectedImage === announcement.id
                        ? "announcement-card Card"
                        : "Card"
                    }
                  >
                    <img
                      id={announcement.id}
                      onClick={onSelect}
                      className="announcement-image"
                      src={APILINK + announcement.image}
                    />
                  </div>
                </Col>
              );
            })
          ) : (
            <h4 className="text">No Announcements Yet</h4>
          )}
        </Row>
        <Row>
          <Col className="align-right" lg={12}>
            <button
              onClick={deleteSelected}
              className="btn-delete"
            >
              Delete
            </button>
            <button
              disabled={disable}
              onClick={onFileUpload}
              className="btn-add mr-2"
            >
              Upload
            </button>
          </Col>
        </Row>

        <Row>
          <Col className="align-left" lg={6}>
            {data.length !== 0 ? (
              <Pagination
                onChange={onChange}
                defaultCurrent={current}
                total={count}
                current={current}
              />
            ) : null}
          </Col>
        </Row>
        <Modal
          className="text-center requestsModel p-3"
          show={show}
          onHide={() => {
            setShow(false);
          }}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <h1 style={{ fontSize: "18px", color: "white" }}>
                  Are you sure you want to delete?{" "}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <button onClick={onDelete} className="requestsBtn mr-2">
                  Delete
                </button>
                <button
                  onClick={() => {
                    setShow(false);
                  }}
                  className="requestsBtn"
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </Container>
        </Modal>
      </Container>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default ErrorHandler(ViewAnnouncements, axiosApiInstance);
