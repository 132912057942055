import React, { useRef, useState } from "react";
import { Container } from "react-bootstrap";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import Resizer from "react-image-file-resizer";

const axiosApiInstance = axios.create();

const AddAnnouncement = (props) => {
  const [file, setFile] = useState({});
  const resizeFile = (file) =>
    new Promise((resolve) => {
      Resizer.imageFileResizer(
        file,
        1024,
        1024,
        "JPEG",
        100,
        0,
        (uri) => {
          resolve(uri);
        },
        "blob"
      );
    });
  const onFileUpload = () => {
    const formData = new FormData();

    formData.append("image", file, file.name);

    let result = axiosApiInstance.post(APILINK + "/announcement", formData);
  };
  const onFileChange = async (event) => {
    // Update the state
    // setFile(event.target.files[0]);
    try {
      const file = event.target.files[0];
      const image = await resizeFile(file);
      console.log(image);
      setFile(image);
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="page-header">
      <Container>
        <input type="file" id="actual-btn" onChange={onFileChange} />
        <label className="btn-label" for="actual-btn">
          Choose File
        </label>
        <button onClick={onFileUpload}>Upload</button>
      </Container>
    </div>
  );
};

export default ErrorHandler(AddAnnouncement, axiosApiInstance);
