import React, { Component } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Select } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/leads.css";
const axiosApiInstance = axios.create();

class EditPackage extends Component {
  state = {
    title: "",
    price: "",
    des: "",
    limit: "",
    selectedExType: "",
    selectedType: "",
    month: "",
  };
  getDetails = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/package_details/${this.props.match.params.id}`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result);
      this.setState({
        title: result.data.title,
        price: result.data.price,
        desc: result.data.description,
        limit: result.data.session_limit,
        selectedExType: result.data.expiration_type,
        selectedType: result.data.package_type,
        month: result.data.month_count,
      });
    }
  };
  componentDidMount() {
    this.getDetails();
  }
  handleType = (v, e) => {
    console.log(e.value);
    this.setState({ selectedType: e.value });
  };
  handleExType = (v, e) => {
    console.log(e.value);
    this.setState({ selectedExType: e.value });
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  edit = async () => {
    let data = {
      title: this.state.title,
      package_type: this.state.selectedType,
      session_limit: this.state.limit,
      price: this.state.price,
      description: this.state.desc,
      expiration_type: this.state.selectedExType,
      month_count: this.state.month,
    };

    let result = await axiosApiInstance.put(
      APILINK + `/package_details/${this.props.match.params.id}`,
      data
    );
    console.log(result);
    if (result) {
      if (result.data.id) {
        setTimeout(() => {
          toast.info("Package has been updated successfully");
        }, 500);
        setTimeout(() => {
          this.props.history.goBack();
        }, 2000);
      }
    }
  };

  render() {
    return (
      <div className="page-header">
        <Container>
          <Form id="infoForm">
            <Row>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Title"
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Price</Form.Label>
                  <Form.Control
                    name="price"
                    value={this.state.price}
                    onChange={this.handleChange}
                    type="number"
                    placeholder="Price"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={6}>
                <Form.Label> Package Type</Form.Label>

                <Select
                  allowClear
                  placeholder="Package Type"
                  value={this.state.selectedType}
                  onChange={this.handleType}
                  style={{ width: "100%" }}
                  onClick={this.getColleges}
                >
                  <Select.Option value={"1"}>Revolt Live</Select.Option>
                  <Select.Option value={"2"}>Group training</Select.Option>
                  <Select.Option value={"3"}>Gym</Select.Option>
                </Select>
              </Col>
              <Col sm={12} md={6}>
                <Form.Label> Expiration Type</Form.Label>

                <Select
                  allowClear
                  placeholder="Expiration Type"
                  value={this.state.selectedExType}
                  onChange={this.handleExType}
                  style={{ width: "100%" }}
                  onClick={this.getColleges}
                >
                  <Select.Option value={"1"}>Monthly</Select.Option>
                  <Select.Option value={"2"}>Annually</Select.Option>
                </Select>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Description</Form.Label>
                  <Form.Control
                    name="desc"
                    value={this.state.desc}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Description"
                  />
                </Form.Group>
              </Col>
              {this.state.selectedType == "3" ? null : (
                <Col sm={12} md={6}>
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Session Limit</Form.Label>
                    <Form.Control
                      name="limit"
                      value={this.state.limit}
                      onChange={this.handleChange}
                      type="text"
                      placeholder="Session Limit"
                    />
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row>
              {this.state.selectedExType == "1" ? (
                <Col sm="12" md="6">
                  <Form.Group controlId="exampleForm.ControlInput1">
                    <Form.Label>Month Number</Form.Label>
                    <Form.Control
                      name="limit"
                      value={this.state.month}
                      onChange={this.handleChange}
                      type="number"
                      placeholder="Month Number"
                    />
                  </Form.Group>
                </Col>
              ) : null}
            </Row>
            <Row>
              {" "}
              <Col sm={12}>
                <Button onClick={this.edit} className="submit-btn-add">
                  Edit
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

export default ErrorHandler(EditPackage, axiosApiInstance);
