import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import {
  Container,
  Row,
  Col,
  Table,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Pagination } from "antd";
import "react-toastify/dist/ReactToastify.css";

const axiosApiInstance = axios.create();

const Branches = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [show , setShow] = useState(false)
  const [selectedBranch, setSelectedBranch] = useState("")
  const [current, setCurrent] = useState(1);
  const [count, setCount] = useState(1);

  const getBranches = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/branch_create`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result);
      setData(result.data.data);
      setCount(result.data.count);
    }
  };

  const deleting = (e) => {
    setSelectedBranch(e.target.id)
    console.log(e.target.id)
    setShow(true)
  }
  const onDelete = async (e) => {
    let result = await axiosApiInstance.delete(
      APILINK + `/branch_delete/${selectedBranch}`
    );
    getBranches();
    setShow(false);
  };


  const onChange = async (page) => {
    console.log(page);
    setCurrent(page);
    let result = await axiosApiInstance.get(
      APILINK + `/branch_create?page=${page}`
    );
    setData(result.data.data);
    setCount(result.data.count);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getBranches();
  }, []);
  return (
    <div className="page-header">
      <Container className="">
        <Row>
          <Col className="align-left" lg={12}>
            <h2>
              {" "}
              <i class="fas fa-code-branch pr-2"></i> Branches
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="align-right" lg={12}>
            <button
              className="btn-add"
              onClick={() => {
                props.history.push("/add_branch");
              }}
            >
              Add New Branch
            </button>
          </Col>
        </Row>
        <Row>
          {data.length !== 0 ? (
            data.map((branch) => {
              return (
                <Col key={branch.id} lg={4} md={6} sm={12}>
                  <div className="Card">
                    <i class="fas card-home fa-home"></i>
                    <p>{branch.title}</p>
                    <p>{branch.address}</p>
                    <button
                    className="btn-card"
                      onClick={() => {
                        props.history.push(`/branch/${branch.id}`);
                      }}
                    >
                      View Branch
                    </button>
                    <button
                    className="btn-card"
                      onClick={() => {
                        props.history.push(`/packages/${branch.id}`);
                      }}
                    >
                      View Packages
                    </button>
                    <p>
                      <i
                        onClick={() => {
                          props.history.push(`/edit_branch/${branch.id}`);
                        }}
                        className="fas fa-edit pr-3"
                      ></i>
                      <i onClick={deleting} id={branch.id}
                        className="fas fa-trash pr-3"></i>
                      {/* <i  id={branch.id} className="fas fa-trash"></i> */}
                    </p>
                  </div>
                </Col>
              );
            })
          ) : (
            <h4 className="text">No Branches Yet</h4>
          )}
        </Row>

        <Row>
          {data.length !== 0 ? (
            <Pagination
              onChange={onChange}
              defaultCurrent={current}
              total={count}
              current={current}
            />
          ) : null}
        </Row>
      </Container>
      <Modal
          className="text-center requestsModel p-3"
          show={show}
          onHide={() => {
            setShow(false);
          }}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <h1 style={{ fontSize: "18px", color: "white" }}>
                  Are you sure you want to delete?{" "}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <button onClick={onDelete} className="requestsBtn mr-2">
                  Delete
                </button>
                <button
                  onClick={() => {
                    setShow(false);
                  }}
                  className="requestsBtn"
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </Container>
        </Modal>
    </div>
  );
};

export default ErrorHandler(Branches, axiosApiInstance);
