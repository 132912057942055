import React, { useEffect, useState, useRef } from "react";
import ReactDOM from 'react-dom';
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input } from "antd";
import 'draft-js/dist/Draft.css';
import RichTextEditor, { InlineStyleControls, BlockStyleControls } from "./richTextComponent";
import '../../css/draft.css'
import { convertFromHTML, ContentState, Editor, EditorState, getDefaultKeyBinding, RichUtils, convertToRaw } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html';





const axiosApiInstance = axios.create();

const EditArticle = (props) => {
    const [editorState, setEditorState] = useState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
            convertFromHTML('<p>3aml a ya jjjjjjjjjjjjjj mohamed w e gouna 3mla a&nbsp;<strong><span style="color:#ffffff">bold</span><em><span style="color:#ffffff"> </span><span style="color:#e74c3c">sadsad</span></em></strong></p>')
        )
    ))
    const [edit, setEdit] = useState(true)
    const [data, setData] = useState([]);
    const [title, setTitle] = useState(data.title)
    const [image, setImage] = useState("")
    const [imageName, setImageName] = useState("")
    const [description, setDescription] = useState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
            convertFromHTML("<p>test</p>"))))

    const getArticle = async () => {
        let result = await axiosApiInstance
            .get(APILINK + `/articles/${props.match.params.id}`)
            .catch((err) => {
                console.log(err);
            });
        if (result) {
            console.log(result)
            setData(result.data);
            setTitle(result.data.title)
            setDescription(result.data.description)

        }
    }

    useEffect(() => {
        getArticle()
    }, [])
    const onEdit = async () => {
        let result
        let data = {
            image: image,
            image_name: imageName,
            title: title,
            description: description
        }
        for (let key in data) {
            if (data[key] === "" || data[key] === undefined) {
                console.log("should delete")
                delete data[key]
            }
        }
        result = await axiosApiInstance.put(APILINK + `/articles/${props.match.params.id}`, data)
            .catch(err => {
                console.log(err)
            })



        if (result) {
            setTimeout(() => {
                toast.info("Article has been edited successfully");
            }, 500);
            setTimeout(() => {
                props.history.push(`/articles`);
            }, 2000);
        }

    }

    useEffect(() => {
        if (data.description) {
            setEditorState(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(data.description)
                )
            ))
        }

    }, [data])

    const getText = (e) => {
        setDescription(e)
        console.log(e)
    }

    function getBlockStyle(block) {
        switch (block.getType()) {
            case 'blockquote': return 'RichEditor-blockquote';
            default: return null;
        }
    }

    const styleMap = {
        CODE: {
            backgroundColor: 'rgba(0, 0, 0, 0.05)',
            fontFamily: '"Inconsolata", "Menlo", "Consolas", monospace',
            fontSize: 16,
            padding: 2,
        },
    };

    const toggleBlockType = (blockType) => {
        onChange(
            RichUtils.toggleBlockType(
                this.state.editorState,
                blockType
            )
        );
    }

    const toggleInlineStyle = (inlineStyle) => {
        onChange(
            RichUtils.toggleInlineStyle(
                editorState,
                inlineStyle
            )
        );
    }

    const handleKeyCommand = (command, editorState) => {
        const newState = RichUtils.handleKeyCommand(editorState, command);
        if (newState) {
            onChange(newState);
            return true;
        }
        return false;
    }

    const mapKeyToEditorCommand = (e) => {
        if (e.keyCode === 9 /* TAB */) {
            const newEditorState = RichUtils.onTab(
                e,
                editorState,
                4, /* maxDepth */
            );
            if (newEditorState !== editorState) {
                onChange(newEditorState);
            }
            return;
        }
        return getDefaultKeyBinding(e);
    }
    const el = useRef(null)
    const focus = () => {
        el.current.focus()
    }
    const onChange = (editorState) => {
        console.log(editorState)
        setEditorState(editorState)
        setEdit(false)

    };
    useEffect(() => {
        const blocks = convertToRaw(editorState.getCurrentContent()).blocks;
        const mappedBlocks = blocks.map(
            block => (!block.text.trim() && "\n") || block.text
        );

        let newText = "";
        for (let i = 0; i < mappedBlocks.length; i++) {
            const block = mappedBlocks[i];

            // handle last block
            if (i === mappedBlocks.length - 1) {
                newText += block;
            } else {
                // otherwise we join with \n, except if the block is already a \n
                if (block === "\n") newText += block;
                else newText += block + "\n";
            }
        }
        getText(stateToHTML(editorState.getCurrentContent()))

    }, [editorState, edit])
    return (
        <div className="page-header">
            <Container className="">
                <Row>
                    <Col className="align-left" lg={12}>
                        <h2>
                            {" "}
                            <i className="fas fa-user pr-3"></i>Edit Article
                        </h2>
                    </Col>
                </Row>
                {data ? <React.Fragment></React.Fragment> : null}
                <Row>

                    <Col lg={12}>
                        <label className="label">Description</label>

                        <div className="RichEditor-root">
                            <BlockStyleControls
                                editorState={editorState}
                                onToggle={toggleBlockType}
                            />
                            <InlineStyleControls
                                editorState={editorState}
                                onToggle={toggleInlineStyle}
                            />
                            <div onClick={focus}>
                                <Editor
                                    blockStyleFn={getBlockStyle}
                                    customStyleMap={styleMap}
                                    editorState={editorState}
                                    handleKeyCommand={handleKeyCommand}
                                    keyBindingFn={mapKeyToEditorCommand}
                                    onChange={onChange}
                                    placeholder="Tell a story..."
                                    ref={el}
                                    spellCheck={true}
                                    readOnly={edit}
                                />
                            </div>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-4">
                    <Col lg={6}>
                        <label className="label">Title</label>

                        <Input type="text" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </Col>
                    <Col lg={6}>
                        <label className="label">Image</label>

                        <Input type="file" name={imageName} onChange={(e) => {
                            let reader = new FileReader();
                            setImageName(e.target.files[0].name)
                            // setImage(e.target.files[0])
                            reader.readAsDataURL(e.target.files[0]);
                            reader.onload = (e) => {
                                setImage(e.target.result)
                            }
                        }} />
                        <img className="image-edit" src={APILINK + "/media/" + data.image} />
                    </Col>
                </Row>

                <Row>
                    <Col lg={12}>
                        <button className="submit-btn-add" onClick={onEdit}>Edit</button>
                    </Col>
                </Row>
            </Container>
            <ToastContainer position="bottom-right" />

        </div>
    );
};

export default ErrorHandler(EditArticle, axiosApiInstance);
