import React, { useEffect, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { Pagination, Image } from "antd";
import { stateFromHTML } from 'draft-js-import-html';
import "react-toastify/dist/ReactToastify.css";
import { convertFromHTML, ContentState, Editor,EditorState } from 'draft-js'

const axiosApiInstance = axios.create();
const html = `<p>Lorem ipsum <b>dolor</b> sit amet, <i>consectetuer adipiscing elit.</i></p>
      <p>Aenean commodo ligula eget dolor. <b><i>Aenean massa.</i></b></p>`;
const blocksFromHTML = convertFromHTML(html);
const content = ContentState.createFromBlockArray(
    blocksFromHTML.contentBlocks,
    blocksFromHTML.entityMap
);

const sss = EditorState.createWithContent(content)
const Article = (props) => {
    const [data, setData] = useState({});
    const [selectedArticle, setSelectedArticle] = useState("")
    const [show, setShow] = useState(false)
    const [editorState, setEditorState] = useState(EditorState.createWithContent(
        ContentState.createFromBlockArray(
            convertFromHTML('<p>3aml a ya jjjjjjjjjjjjjj mohamed w e gouna 3mla a&nbsp;<strong><span style="color:#ffffff">bold</span><em><span style="color:#ffffff"> </span><span style="color:#e74c3c">sadsad</span></em></strong></p>')
        )
    ))




    const getArticle = async () => {
        let result = await axiosApiInstance
            .get(APILINK + `/articles/${props.match.params.id}`)
            .catch((err) => {
                console.log(err);
            });
        if (result) {
            console.log(result.data.description)
            console.log(result)
            setData(result.data);
        }
    };

    
    useEffect(()=>{
        if(data.description){
            setEditorState(EditorState.createWithContent(
                ContentState.createFromBlockArray(
                    convertFromHTML(data.description)
                )
            ))
        }
        
    },[data])
    useEffect(() => {
        window.scrollTo(0, 0);
        console.log(editorState)
        getArticle();
    }, []);
    return (
        <div className="page-header">
            <Container className="">
                <Row>
                    <Col className="align-left" lg={12}>
                        <h2>
                            {" "}
                            <i class="fas fa-user-ninja"></i>Articles
                        </h2>
                    </Col>
                </Row>

                <Row>
                    {data.description ? (

                        <Col key={data.id} lg={12} md={12} sm={12}>
                            <div id={data.id} className="article-card">
                                <h1>{data.title}</h1>

                                <Image
                                    id={data.id}
                                    className="announcement-image"
                                    src={APILINK + "/media/" + data.image}
                                />
                                
                                <Editor
                                className="rich-text-article"
                                editorState={editorState}
                                />
                            </div>
                        </Col>

                    ) : (
                        <h4 className="text">No Articles Yet</h4>
                    )}
                </Row>



              
            </Container>
        </div>
    );
};

export default ErrorHandler(Article, axiosApiInstance);
