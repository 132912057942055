import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input, Checkbox, DatePicker, TimePicker, Select } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";

const axiosApiInstance = axios.create();

const AddSession = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue, watch
  } = useForm();
  const [branches, setBranches] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [type, setType] = useState("")
  const [group, setGroup] = useState(false);
  const getChangeHandlerWithValue = (name) => (value, val2) => {
    setValue(name, val2);
  };
  const selectedType = watch('session_type');
  const selectedTrainer = watch('trainer')
  const selectedBranch = watch('branch')
  const onSubmit = async (data) => {
    data.is_group = group;
    console.log(data);

    let result = await axiosApiInstance
      .post(APILINK + `/session`, data)
      .catch((err) => console.log(err));

    if (result) {
      setTimeout(() => {
        toast.info("Session has been created successfully");
      }, 500);
      setTimeout(() => {
        props.history.push("/sessions");
      }, 2000);
    }
  };

  const getBranches = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/branch_create`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result);
      setBranches(result.data.data);
    }
  };
  const handleBranch = (e) => {
    setValue('branch', e.target.value)
  }

  const handleType = (e) => {
    console.log("changed")
    setValue('type', e.target.value)
    setType(e.target.value)
  }

  const handleTrainer = (e) => {
    setValue('trainer', e.target.value)
  }


  const getTrainers = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/admin_signup?user_type=2`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result.data.data);
      setTrainers(result.data.data);
    }
  };
  useEffect(()=>{
    console.log(type)
  },[type])

  useEffect(() => {
    window.scrollTo(0, 0);
    getBranches();
    getTrainers();
  }, []);
  return (
    <div className="page-header">
      <Container>
        <Row>
          <Col lg={12}>
            <h2>Session Info</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <React.Fragment>
                <Row>
                  <Col lg={6}>
                    <label className="label">Title</label>
                    <Input
                      type="text"
                      {...register("title", { required: true })}
                    />
                    <p className="error">
                      {errors.title?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label"> Session Type</label>
                    <select
                      onChange={handleType}
                      value={selectedType}
                      className="w-100"
                      {...register("session_type", { required: true })}

                    >
                      <option value="" selected hidden disabled>Select Type</option>

                      <option value="1">Revolt Live</option>
                      <option value="2"> Group training</option>
                    </select>
                    <p className="error">
                      {errors.session_type?.type === "required" &&
                        "This field is required"}
                    </p>

                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">Branch</label>
                    <select
                      className="w-100"
                      onChange={handleBranch}
                      value={selectedBranch}
                      {...register("branch_id", {
                        required: "This field is required"
                      })}
                    >
                      <option value="" selected disabled hidden>
                        Choose your branch
                      </option>

                      {branches.length > 0
                        ? branches.map((branch) => {
                          return (
                            <option key={branch.id} value={branch.id}>
                              {branch.title}
                            </option>
                          );
                        })
                        : null}
                    </select>
                    {errors.branch_id && (<p style={{ color: "red" }}>{errors.branch_id.message}</p>)}

                  </Col>
                  <Col lg={6}>
                    <label className="label">Trainer</label>
                    <select
                      className="w-100"
                      onChange={handleTrainer}
                      value={selectedTrainer}
                      {...register("trainer_id", {
                        required: "This field is required"
                      })}

                    >
                      <option value="" selected disabled hidden>
                        Choose your Trainer
                      </option>
                      {trainers.length > 0
                        ? trainers.map((trainer) => {
                          return (
                            <option key={trainer.id} value={trainer.id}>
                              {trainer.full_name + " " + trainer.last_name}
                            </option>
                          );
                        })
                        : null}
                    </select>
                    {errors.trainer_id && (<p style={{ color: "red" }}>{errors.trainer_id.message}</p>)}
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <label className="label">Session Start Time</label>
                    <TimePicker
                      {...register("start_time", { required: true })}
                      className="w-100"
                      onChange={getChangeHandlerWithValue("start_time")}
                    />
                    <p className="error">
                      {errors.start_time?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>

                  <Col lg={6}>
                    <label className="label">Session Date</label>

                    <DatePicker
                      {...register("start_date_", { required: true })}
                      className="w-100"
                      onChange={getChangeHandlerWithValue("start_date_")}
                    />
                    <p className="error">
                      {errors.start_date_?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
                {selectedType == "1" ? <>
                  <Row>
                    <Col lg={6}>
                      <label className="label">Meeting ID</label>
                      <Input
                        type="number"
                        {...register("meeting_id", { required: true, min: 0, maxLength: 20 })}
                      />
                      <p className="error">
                        {errors.meeting_id?.type === "required" &&
                          "This field is required"}
                      </p>
                      <p className="error">
                        {errors.meeting_id?.type === "min" &&
                          "Negative values are not allowed"}
                      </p>
                    </Col>
                    <Col lg={6}>
                      <label className="label">Passcode</label>
                      <Input
                        type="text"
                        {...register("passcode", { required: true, maxLength: 20 })}
                      />
                      <p className="error">
                        {errors.passcode?.type === "required" &&
                          "This field is required"}
                      </p>

                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6}>
                      <label className="label">Participate ID</label>
                      <Input
                        type="text"
                        {...register("participate_id", { required: true, maxLength: 20 })}
                      />
                      <p className="error">
                        {errors.participate_id?.type === "required" &&
                          "This field is required"}
                      </p>

                    </Col>
                    <Col lg={6}>
                      <label className="label">Meeting Link</label>
                      <Input
                        type="text"
                        {...register("link", { required: true })}
                      />
                      <p className="error">
                        {errors.link?.type === "required" &&
                          "This field is required"}
                      </p>

                    </Col>
                  </Row>
                </> : null}

                <Row>
                  <Col style={{ textAlign: "left" }} lg={6}>
                    <Checkbox
                      checked={group}
                      onChange={(e) => {
                        setGroup(e.target.checked);
                      }}
                    >
                      Group?
                    </Checkbox>
                    {group ? (
                      <React.Fragment>
                        <label className="label">Group Size</label>
                        <Input
                          class
                          type="number"
                          {...register("group_size", { required: true, min: 1 ,max : 100 })}
                        />
                        <p className="error">
                          {errors.group_size?.type === "required" &&
                            "This field is required"}
                        </p>
                        <p className="error">
                          {errors.group_size?.type === "min" &&
                            "Minimum value is one"}
                        </p>
                        <p className="error">
                          {errors.group_size?.type === "max" &&
                            "Maximum value is 100"}
                        </p>
                      </React.Fragment>
                    ) : null}
                  </Col>
                </Row>
              </React.Fragment>

              <input className="submit-btn-add" value="Add" type="submit" />
            </form>
          </Col>
        </Row>

        <ToastContainer position="bottom-right" />
      </Container>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  privilege: state.auth.privilege,
});
export default connect(mapStateToProps)(
  ErrorHandler(AddSession, axiosApiInstance)
);
