import React, { useState, useRef } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input, Checkbox, DatePicker } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";

const axiosApiInstance = axios.create();

const AddTrainer = (props) => {
  const [image, setImage] = useState("");
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();
  const [online, setOnline] = useState(false);
  const [onground, setOnground] = useState(false);
  const [nutrition, setNutrition] = useState(false);

  const onFileUpload = (e) => {
    e.preventDefault();

    setImage(e.target.files[0]);
  };

  const getChangeHandlerWithValue = (name) => (value, val2) => {
    setValue(name, val2);
  };
  const onSubmit = async (data) => {
    var formData = new FormData();
    data.nutrition = nutrition;
    data.onground = onground;
    data.online = online;
    formData.append("image", image);
    for (var key in data) {
      formData.append(key, data[key]); // formdata doesn't take objects
    }
    console.log(data);

    let result = await axiosApiInstance.post(
      APILINK + `/trainer_signup`,
      formData
    );

    if (result) {
      setTimeout(() => {
        toast.info("Trainer has been created successfully");
      }, 500);
      setTimeout(() => {
        props.history.push("/trainers");
      }, 2000);
    }
  };

  return (
    <div className="page-header">
      <Container>
        <Row>
          <Col lg={12}>
            <h2>Trainer Info</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <React.Fragment>
                <Row>
                  <Col lg={6}>
                    <label className="label">First Name</label>
                    <Input
                      type="text"
                      {...register("full_name", { required: true })}
                    />
                    <p className="error">
                      {errors.full_name?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Last Name</label>
                    <Input
                      type="text"
                      {...register("last_name", { required: true })}
                    />
                    <p className="error">
                      {errors.last_name?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>

                <Row>
                  <Col lg={6}>
                    <label className="label">Password</label>
                    <Input
                      type="password"
                      {...register("password", { required: true })}
                    />
                    <p className="error">
                      {errors.password?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Email</label>
                    <Input
                      type="email"
                      {...register("email", { required: true })}
                    />
                    <p className="error">
                      {errors.email?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">Address</label>
                    <Input
                      type="text"
                      {...register("address", { required: true })}
                    />
                    <p className="error">
                      {errors.address?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Title</label>
                    <Input
                      type="text"
                      {...register("title", { required: true })}
                    />
                    <p className="error">
                      {errors.title?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">Mobile</label>
                    <Input
                      type="text"
                      {...register("mobile", { required: true })}
                    />
                    <p className="error">
                      {errors.mobile?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">WhatsApp</label>
                    <Input
                      type="text"
                      {...register("whtsapp", { required: true })}
                    />
                    <p className="error">
                      {errors.whtsapp?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">Twitter</label>
                    <Input
                      type="text"
                      {...register("twitter", { required: true })}
                    />
                    <p className="error">
                      {errors.twitter?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Instagram</label>
                    <Input
                      type="text"
                      {...register("instgram", { required: true })}
                    />
                    <p className="error"> 
                      {errors.instgram?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">FaceBook</label>
                    <Input
                      type="text"
                      {...register("fb", { required: true })}
                    />
                    <p className="error">
                      {errors.fb?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Image</label>
                    <input
                      className="w-100"
                      type="file"
                      {...register("image",{required : true})}
                      onChange={onFileUpload}
                    />
                    <p style={{color : "white"}}>Recommended image size is 145px X 145px</p>
                    <p className="error">
                      {errors.image?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={4}>
                    <Checkbox
                      checked={nutrition}
                      onChange={(e) => {
                        setNutrition(e.target.checked);
                      }}
                    >
                      Nutrition
                    </Checkbox>
                  </Col>
                  <Col lg={4}>
                    <Checkbox
                      checked={onground}
                      onChange={(e) => {
                        setOnground(e.target.checked);
                      }}
                    >
                      On Ground
                    </Checkbox>
                  </Col>
                  <Col lg={4}>
                    <Checkbox
                      checked={online}
                      onChange={(e) => {
                        setOnline(e.target.checked);
                      }}
                    >
                      Online
                    </Checkbox>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">About Trainer</label>
                    <Input.TextArea
                      type=""
                      {...register("about", { required: true })}
                    />
                    <p>
                      {errors.about?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>

                  <Col lg={6}>
                    <label className="label">Birth Date</label>

                    <DatePicker
                      {...register("birth_date")}
                      className="w-100"
                      onChange={getChangeHandlerWithValue("birth_date")}
                    />
                  </Col>
                </Row>
              </React.Fragment>

              <input className="submit-btn-add" value="Add" type="submit" />
            </form>
          </Col>
        </Row>

        <ToastContainer position="bottom-right" />
      </Container>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  privilege: state.auth.privilege,
});
export default connect(mapStateToProps)(
  ErrorHandler(AddTrainer, axiosApiInstance)
);
