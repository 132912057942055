import React, { useState, useEffect } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input, Checkbox, DatePicker, TimePicker, Select } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import moment from "moment";

const axiosApiInstance = axios.create();

const EditSession = (props) => {
  const [branches, setBranches] = useState([]);
  const [trainers, setTrainers] = useState([]);
  const [group, setGroup] = useState(false);
  const [data, setData] = useState("");
  const [branchName, setBranchName] = useState("");
  const [trainerName, setTrainerName] = useState("");
  const [trainerID, setTrainerID] = useState("");
  const [branchID, setBranchID] = useState("");
  const [title, setTitle] = useState("");
  const [startDate, setStartDate] = useState("");
  const [startTime, setStartTime] = useState("");
  const [groupSize, setGroupSize] = useState("");
  const [meetingID, setMeetingID] = useState("")
  const [participantID, setParticipantID] = useState("")
  const [passcode, setPasscode] = useState("")
  const [link, setLink] = useState("")

  const getDetails = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/session/${props.match.params.id}`)
      .catch((err) => console.log(err));
    if (result) {
      console.log(result.data);
      setData(result.data);
      setTrainerID(result.data.trainer.id);
      setBranchID(result.data.branch.id);
      setTitle(result.data.title);
      setStartDate(result.data.start_date.date);
      setGroup(result.data.is_group);
      setStartTime(result.data.start_time);
      setGroupSize(result.data.group_size);
      setBranchName(result.data.branch.title);
      setTrainerName(result.data.trainer.full_name);
    }
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    let data = {};
    data.start_time = startTime;
    data.start_date = startDate;
    data.title = title;
    data.is_group = group;
    data.branch_id = branchID;
    data.trainer_id = trainerID;
    console.log(data);
    if (group) {
      data.group_size = groupSize;
    }
    let result = await axiosApiInstance
      .put(APILINK + `/session/${props.match.params.id}`, data)
      .catch((err) => console.log(err));

    if (result) {
      setTimeout(() => {
        toast.info("Session has been edited successfully");
      }, 500);
      setTimeout(() => {
        props.history.push("/sessions");
      }, 2000);
    }
  };

  const getBranches = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/branch_create`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result);
      setBranches(result.data.data);
    }
  };

  const getTrainers = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/admin_signup?user_type=2`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result.data.data);
      setTrainers(result.data.data);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getDetails();
    getBranches();
    getTrainers();
  }, []);
  return (
    <div className="page-header">
      <Container>
        <Row>
          <Col lg={12}>
            <h2>Session Info</h2>
            <form>
              <React.Fragment>
                {data ? (
                  <React.Fragment>
                    {" "}
                    <Row>
                      <Col lg={6}>
                        <label className="label">Title</label>
                        <Input
                          value={title}
                          type="text"
                          onChange={(e) => {
                            setTitle(e.target.value);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <label className="label">Branch</label>
                        <Select
                          defaultValue={branchID.title}
                          value={branchName}
                          id={branchID}
                          className="w-100"
                          onChange={(v, e) => {
                            setBranchID(e.value);
                            setBranchName(e.children);
                          }}
                        >
                          {branches.length > 0
                            ? branches.map((branch) => {
                              return (
                                <Select.Option value={branch.id}>
                                  {branch.title}
                                </Select.Option>
                              );
                            })
                            : null}
                        </Select>
                      </Col>
                      <Col lg={6}>
                        <label className="label">Trainer</label>
                        <Select
                          defaultValue={trainerID.full_name}
                          value={trainerName}
                          id={trainerID}
                          className="w-100"
                          onChange={(v, e) => {
                            setTrainerID(e.value);
                            setTrainerName(e.children);
                          }}
                        >
                          {trainers.length > 0
                            ? trainers.map((trainer) => {
                              return (
                                <Select.Option value={trainer.id}>
                                  {trainer.full_name +
                                    " " +
                                    trainer.last_name}
                                </Select.Option>
                              );
                            })
                            : null}
                        </Select>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        <label className="label">Session Start Time</label>
                        <TimePicker
                          defaultValue={moment(data.start_time, "HH:mm:ss")}
                          className="w-100"
                          onChange={(value, stringValue) => {
                            setStartTime(stringValue);
                          }}
                        />
                      </Col>

                      <Col lg={6}>
                        <label className="label">Session Date</label>

                        <DatePicker
                          defaultValue={moment(data.start_date.data)}
                          className="w-100"
                          onChange={(value, stringValue) => {
                            setStartDate(stringValue);
                          }}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col style={{ textAlign: "left" }} lg={6}>
                        <label className="label">Session Type</label>

                        <Checkbox
                          defaultChecked={data.is_group}
                          checked={group}
                          onChange={(e) => {
                            setGroup(e.target.checked);
                          }}
                        >
                          Group?
                        </Checkbox>
                        {group ? (
                          <React.Fragment>
                            <label className="label">Group Size</label>
                            <Input
                              defaultValue={data.group_size}
                              type="number"
                              onChange={(e) => {
                                setGroupSize(e.target.value);
                              }}
                            />
                          </React.Fragment>
                        ) : null}
                      </Col>
                    </Row>
                    {data.session_type === "1" ? <>
                      <Row>
                        <Col>
                          <label className="label">Meeting ID</label>
                          <Input
                            defaultValue={data.meeting_id}
                            type="text"
                            onChange={(e) => {
                              setMeetingID(e.target.value);
                            }}
                          />
                        </Col>
                        <Col>
                          <label className="label">Participant ID</label>
                          <Input
                            defaultValue={data.participate_id}
                            type="number"
                            onChange={(e) => {
                              setParticipantID(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <label className="label">Passcode</label>
                          <Input
                            defaultValue={data.passcode}
                            type="text"
                            onChange={(e) => {
                              setPasscode(e.target.value);
                            }}
                          />
                        </Col>
                        <Col>
                          <label className="label">Meeting Link</label>
                          <Input
                            defaultValue={data.link}
                            type="text"
                            onChange={(e) => {
                              setLink(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                    </> : null}
                  </React.Fragment>
                ) : null}
              </React.Fragment>

              <input
                className="submit-btn-add"
                onClick={onSubmit}
                value="Edit"
                type="submit"
              />
            </form>
          </Col>
        </Row>

        <ToastContainer position="bottom-right" />
      </Container>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  privilege: state.auth.privilege,
});
export default connect(mapStateToProps)(
  ErrorHandler(EditSession, axiosApiInstance)
);
