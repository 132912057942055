import React, { useState, useEffect } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input, Select, Button } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import Geocode from "react-geocode";
import ViewMap from "../ViewMap";
import { Progress } from "reactstrap";
const axiosApiInstance = axios.create();

const AddBranch = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  // const [title, setTitle] = useState([]);
  const [address, setAddress] = useState("");
  const [fileType , setFileType] = useState(true)
  const [imagesArray, setImagesArray] = useState([]);
  const [loaded, setLoaded] = useState(0);
  const [location, setLocation] = useState({
    lat: 50.937531,
    lon: 6.9602,
  });
  const [disabled , setDisabled] = useState(false)

  const onSubmit = async (data) => {
    let formData = new FormData();
    // console.log(imagesArray[0])
    // formData.append('images', imagesArray);
    console.log(imagesArray.length);
    for (var x = 0; x < imagesArray.length; x++) {
      formData.append("images", imagesArray[x]);
    }
    data.longitude = location.lon;
    data.latitude = location.lat;
    console.log(data);
    for (let key in data) {
      formData.append(key, data[key]);
    }

    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }
    let result = await axiosApiInstance.post(
      APILINK + `/branch_create`,
      formData,
      {
        onUploadProgress: (ProgressEvent) => {
          setLoaded((ProgressEvent.loaded / ProgressEvent.total) * 100);
        },
        headers: {
          "content-type": "multipart/form-data",
        },
      }
    ).catch(err=>{
      setDisabled(false)
    })
    setDisabled(true)
    if (result) {
      setDisabled(true)
      setTimeout(() => {
        toast.info("Branch has been created successfully");
      }, 500);
      setTimeout(() => {
        props.history.push("/branches");
      }, 2000);
    }
  };

  const onImgChange = (e) => {
    console.log(e.target.files)
    let fileTypes = []
    for(let file of e.target.files){
     
      fileTypes.push(file.type)
    }
    if(fileTypes.filter(file=>file.includes("image")).length !== fileTypes.length){
      setFileType(false)
    }
    else{
    setFileType(true)
    setImagesArray(e.target.files);

    }
  };
  const handleAddress = (e) => {
    setAddress(e.target.value);
    // Get latitude & longitude from address.
    Geocode.fromAddress(address).then(
      (response) => {
        const { lat, lng } = response.results[0].geometry.location;
        setLocation({ lat: lat, lon: lng });

        console.log(lat, lng);
      },
      (error) => {
        console.error(error);
      }
    );
  };
  useEffect(() => {
    console.log(disabled)
   
  }, [disabled])
  return (
    <div className="page-header">
      <Container>
        <Row>
          <Col lg={12}>
            <h2>Branch Info</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <React.Fragment>
                <Row>
                  <Col lg={6}>
                    <label className="label">Title</label>
                    <Input
                      type="text"
                      {...register("title", { required: true })}
                    />
                    <p className="error">
                      {errors.title?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Email</label>
                    <Input
                      type="email"
                      {...register("email", { required: true })}
                    />
                    <p className="error">
                      {errors.email?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">Opening</label>
                    <Input
                      type="text"
                      {...register("opening", { required: true })}
                    />
                    <p className="error">
                      {errors.opening?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Closing</label>
                    <Input
                      type="text"
                      {...register("closing", { required: true })}
                    />
                    <p className="error">
                      {errors.closing?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">Phone</label>
                    <Input
                      type="text"
                      {...register("phone", { required: true })}
                    />
                    <p className="error">
                      {errors.phone?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Address</label>
                    <Input
                      type="text"
                      onChange={handleAddress}
                      {...register("address", { required: true })}
                    />
                    <p className="error">
                      {errors.address?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">Images</label>
                    <input
                      {...register("image", { required: true })}
                      className="upload"
                      type="file"
                      accept="image/*"
                      multiple
                      onChange={onImgChange}
                    />
                    <Progress max="100" color="success" value={loaded}>
                      {Math.round(loaded, 2)}%
                    </Progress>
                    {
                      !fileType ? <p className="error">Please upload images only</p> : null
                    }
                    <p className="error">
                      {errors.image?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Description</label>
                    <textarea
                      className="w-100"
                      {...register("description", { required: true })}
                    />
                    <p className="error">
                      {errors.description?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  {/* <Col lg={6}>
                    <ViewMap location={location} />
                  </Col> */}
                </Row>
              </React.Fragment>

              <input className="submit-btn-add" value="Add" disabled={disabled} type="submit" />
            </form>
          </Col>
        </Row>

        <ToastContainer position="bottom-right" />
      </Container>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  privilege: state.auth.privilege,
});
export default connect(mapStateToProps)(
  ErrorHandler(AddBranch, axiosApiInstance)
);
