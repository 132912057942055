import React, { useEffect, useState } from 'react'
import axios from 'axios'
import '../css/leads.css'
import logo from '../images/logo.svg'
import { APILINK } from '../EndPoint';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Row, Col, Container, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

const ForgotPassword = (props) => {
    const { register, handleSubmit, formState: { errors } } = useForm();
    const { register: register2, formState: { errors: errors2 }, handleSubmit: handleSubmit2, } = useForm();



    const [show, setShow] = useState(false)

    const hide = () => {
        setShow(false)
    }

    const onSubmit = async (data) => {

        console.log("pressed")
        let result = await axios.post(APILINK + "/forgot_password", data).catch(err => {
            console.log(err.response)
            for (const error in err.response.data) {
                setTimeout(() => {
                    toast.error(`${err.response.data[error][0]}`);
                }, 500);
            }
        })

        if (result) {
            setShow(true)
            setTimeout(() => {
                toast.success("Email Sent Successfully");
            }, 500);
        }
    };

    const onSend = async (data) => {


        let result = await axios.post(APILINK + "/code_response", data).catch(err => {
            console.log(err.response)
            for (const error in err.response.data) {
                setTimeout(() => {
                    toast.error(`${err.response.data[error][0]}`);
                }, 500);
            }
        })

        if (result) {
            setTimeout(() => {
                toast.success("Password reset Successfully");
            }, 500);
            setTimeout(() => {
                props.history.push(`/admin-login`);
            }, 2000);
        }
    };


    return (
        <div className='page-header'>
            <Container className="page-container">
                <Row>
                    <Col lg={6} sm={12} xs={12}>
                        <div className="logo">
                            <img alt="Logo" className="mobile-logo" src={logo}></img>
                        </div>

                    </Col>
                    <Col lg={6} sm={12} xs={12}>
                        <form onSubmit={handleSubmit(onSubmit)} className="form">
                            <label className="label">Email</label>

                            <input className="input" type="email" {...register("email", { required: true })} />
                            <p className="error">{errors.email?.type === 'required' && "Email is required"}</p>
                            <input className="submit-btn" value="Send" type="submit" />

                        </form>
                    </Col>


                </Row>
            </Container>
            <Modal show={show} hide={hide} className="text-center requestsModel p-3">
                <form onSubmit={handleSubmit2(onSend)} className="formModal" >
                    <label className="label">Code</label>

                    <input className="input" type="text" {...register2("code", { required: true })} />
                    <p>{errors2.code?.type === 'required' && "Name is required"}</p>

                    <label className="label">New Password</label>

                    <input className="input" type="password" {...register2("password", { required: true })} />
                    <p>{errors2.password?.type === 'required' && "Name is required"}</p>
                    <button className="resend" onClick={onSend}>Resend Code</button>

                    <input className="submit-btn" type="submit" />

                </form>
            </Modal>
            <ToastContainer position="bottom-right" />
        </div>
    )
}

export default ForgotPassword