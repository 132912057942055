import React from "react";
import axios from "axios";
import { APILINK } from "../EndPoint";
import ErrorHandler from "../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input, DatePicker } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
const axiosApiInstance = axios.create();

const CreateOperation = (props) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm();

  const getChangeHandlerWithValue = (name) => (value, val2) => {
    setValue(name, val2);
  };
  const onSubmit = async (data) => {
    data.user_type = "3";
    console.log(data);

    let result = await axiosApiInstance
      .post(APILINK + `/admin_signup`, data)
      .catch((err) => {
        console.log(err.response);
        for (const error in err.response.data.error_user) {
          setTimeout(() => {
            toast.error(err.response.data.error_user[error][0]);
          }, 500);
        }
      });

    if (result) {
      setTimeout(() => {
        toast.info("Operation account created successfully");
      }, 500);
      setTimeout(() => {
        props.history.push("/operations");
      }, 2000);
    }
  };

  return (
    <div className="page-header">
      <Container>
        <Row>
          <Col lg={12}>
            <h2>Operation Info</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <React.Fragment>
                <Row>
                  <Col lg={6}>
                    <label className="label">First Name</label>
                    <Input
                      type="text"
                      placeholder="first name"
                      {...register("full_name", { required: true })}
                      maxLength={15}
                    />
                    <p className="error">
                      {errors.full_name?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Last Name</label>
                    <Input
                      type="text"
                      placeholder="last name"
                      {...register("last_name", { required: true })}
                      maxLength={15}
                    />
                    <p className="error">
                      {errors.last_name?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">Email</label>
                    <Input
                      type="email"
                      placeholder="example@email.com"
                      {...register("email", { required: true })}
                    />
                    <p className="error">
                      {errors.email?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Password</label>
                    <Input
                      type="password"
                      {...register("password", {
                        required: true,
                        pattern: /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,}$/i,
                      })}
                    />
                    <p className="error">
                      {errors.password?.type === "required" &&
                        "This field is required"}
                    </p>
                    <p className="error">
                      {errors.password?.type === "pattern" &&
                        "Password must have minimum eight characters, at least one letter , one number and no special characters."}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">Country</label>
                    <select {...register("country", { required: true })}>
                      <option value="" selected hidden disabled>
                        Select your country
                      </option>
                      <option>Egypt</option>
                    </select>
                    <p className="error">
                      {errors.country?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">City</label>
                    <select {...register("city", { required: true })}>
                      <option value="" selected hidden disabled>
                        Select your city
                      </option>
                      <option>Cairo</option>
                    </select>
                    <p className="error">
                      {errors.city?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">Mobile</label>
                    <Input
                      type="number"
                      placeholder="01234567890"
                      {...register("mobile", {
                        required: true,
                        maxLength: 11,
                        minLength: 11,
                      })}
                    />
                    <p className="error">
                      {errors.mobile?.type === "required" &&
                        "This field is required"}
                    </p>
                    <p className="error">
                      {errors.mobile?.type === "minLength" &&
                        "Incorrect Number"}
                    </p>
                    <p className="error">
                      {errors.mobile?.type === "maxLength" &&
                        "Incorrect Number"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Address</label>
                    <Input
                      placeholder="example st"
                      type="text"
                      {...register("address", { required: true })}
                    />
                    <p className="error">
                      {errors.address?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">Gender</label>
                    <select {...register("gender", { required: true })}>
                      <option value="" selected hidden disabled>
                        Choose your gender
                      </option>

                      <option value="1">Male</option>
                      <option value="2">Female</option>
                    </select>
                    <p className="error">
                      {errors.gender?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Birth Date</label>
                    <DatePicker
                      {...register("birth_date", { required: true })}
                      className="w-100"
                      onChange={getChangeHandlerWithValue("birth_date")}
                    />
                    <p className="error">
                      {errors.birth_date?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
              </React.Fragment>

              <input className="submit-btn-add" value="Add" type="submit" />
            </form>
          </Col>
        </Row>

        <ToastContainer position="bottom-right" />
      </Container>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  privilege: state.auth.privilege,
});
export default connect(mapStateToProps)(
  ErrorHandler(CreateOperation, axiosApiInstance)
);
