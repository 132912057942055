import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import { useHistory } from "react-router-dom";
import ErrorHandler from "../../error-handler/ErrorHandler";
import {
  Container,
  Row,
  Col,
  Button,
  Table,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Pagination , Spin  } from "antd";
import { LoadingOutlined } from '@ant-design/icons';
import "react-toastify/dist/ReactToastify.css";

const axiosApiInstance = axios.create();
const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const DaysSessions = (props) => {
  let history = useHistory();

  const [showDelete, setShowDelete] = useState(false);
  const [selectedSession, setSelectedSession] = useState("");

  const openModal = async (e) => {
    console.log(e.target.id);
    setShowDelete(true);
    setSelectedSession(e.target.id);
  };

  const deleteSession = async () => {
    let result = await axiosApiInstance.delete(
      APILINK + `/session/${selectedSession}`
    );
    setShowDelete(false);
    props.updated(true);
  };
  useEffect(() => {}, [props.days]);
  return (
    <>
      <Row>
        <Col className="align-left" lg={12}>
          <h2>
            {" "}
            <i class="fas fa-calendar-alt pr-2"></i>Days Sessions
          </h2>
        </Col>
      </Row>
      {props.loading ? <Spin  indicator={antIcon} /> : null}
        
      <Row>
       

        {props.days.length !== 0 ? (
          props.days.map((session) => {
            return (
              <Col key={session.id} lg={4} md={6} sm={12}>
                <div className="Card">
                  <strong>
                    <p>{session.title}</p>
                  </strong>

                  <p>
                    <strong>Branch : </strong>
                    {session.branch.title}
                  </p>
                  <p>
                    {session.trainer !== null ? (
                      <>
                        <strong>Trainer : </strong>
                        {session.trainer.full_name} {session.trainer.last_name}
                      </>
                    ) : null}
                  </p>
                  <p>
                    <strong>Type : </strong>
                    {session.session_type === "1"
                      ? "Revolt Live"
                      : session.session_type === "2"
                      ? "Group Training"
                      : null}
                  </p>
                  <p>
                    <strong>Start Time : </strong>
                    {session.start_time}
                  </p>
                  <p>
                    <strong>Group Size : </strong>
                    {session.group_size}
                  </p>
                  <p>
                    <strong>Current Count : </strong>
                    {session.current_count}
                  </p>

                  <p>
                    <i
                      onClick={() => {
                        history.push(`/edit_session/${session.id}`);
                      }}
                      className="fas fa-edit pr-3"
                    ></i>
                    <i
                      id={session.id}
                      onClick={openModal}
                      className="fas fa-trash"
                    ></i>
                  </p>
                </div>
              </Col>
            );
          })
        ) : (
          <h4 className="text text-center">Select a Day</h4>
        )}
      </Row>

      <Row>
        <Modal
          className="text-center requestsModel p-3"
          show={showDelete}
          onHide={() => {
            setShowDelete(false);
          }}
        >
          <Container className="p-4">
            <Row>
              <Col className="text-center" sm={12}>
                {" "}
                <h6>Are u sure u want to delete this Session?</h6>
              </Col>
              <Row style={{ margin: "auto" }}>
                <Col md={12}>
                  <Button onClick={deleteSession} className="headBtn mx-3">
                    Delete
                  </Button>
                  <Button
                    onClick={() => {
                      setShowDelete(false);
                    }}
                    className="headBtn"
                  >
                    Cancel
                  </Button>
                </Col>
              </Row>
            </Row>
          </Container>
        </Modal>
      </Row>
    </>
  );
};

export default ErrorHandler(DaysSessions, axiosApiInstance);
