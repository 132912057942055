import React, { useState,useEffect } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
const axiosApiInstance = axios.create();
const NotificationModal = ({ show, hide, id }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    reset
  } = useForm();
  useEffect(()=>{
    reset()
  },[show])
  const onSubmit = async (data) => {
    let result = await axiosApiInstance.get(
      APILINK + `/send_noti?title=${data.title}&msg=${data.msg}&user=${id}`
    );

    if (result) {
      if ((result.data = "Notfication Send Succesfully")) {
    
        setTimeout(() => {
          toast.info("Notification has been sent successfully");
        }, 500);
        setTimeout(() => {
            hide();
        }, 1500);
      }
      reset()
    }
  };
  return (
    <Modal className="text-center requestsModel p-3" show={show} onHide={hide}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Container style={{ textAlign: "center" }}>
          <Row>
            <Col className lg={12}>
              <label className="label">Title</label>
              <input
                className="w-100"
                {...register("title", { required: true })}
              />
              <p className="error">
                {errors.title?.type === "required" && "This field is required"}
              </p>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              <label className="label">Message</label>
              <textarea
                className="w-100"
                {...register("msg", { required: true })}
              />
              <p className="error">
                {errors.msg?.type === "required" && "This field is required"}
              </p>
            </Col>
          </Row>
          <input className="modal-btn" value="Send" type="submit" />
        </Container>
      </form>
      <ToastContainer position="bottom-right" />
    </Modal>
  );
};

export default ErrorHandler(NotificationModal, axiosApiInstance);
