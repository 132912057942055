import React, { useEffect, useState } from "react";
import axios from "axios";
import { APILINK } from "../EndPoint";
import ErrorHandler from "../error-handler/ErrorHandler";
import { Container, Row, Col,Modal } from "react-bootstrap";
import { Pagination } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
const axiosApiInstance = axios.create();

const Operations = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [count, setCount] = useState(1);
  const [show , setShow] = useState(false)
  const [selectedOperation , setSelectedOperation] = useState("")
  

  const getOperations = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/admin_signup?user_type=3`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result.data.data);
      setData(result.data.data);
      setCount(result.data.count);
    }
  };

  const deleting = (e) =>{
    setSelectedOperation(e.target.id)
    setShow(true)
  }
  const onDelete = async (e) => {
    let result = await axiosApiInstance.delete(
      APILINK + `/edit_profile/${selectedOperation}`
    );
    getOperations();
    setShow(false);
  };


  
  const onChange = async (page) => {
    console.log(page);
    setCurrent(page);
    let result = await axiosApiInstance.get(
      APILINK + `/admin_signup?user_type=3&page=${page}`
    );
    setData(result.data.data);
    setCount(result.data.count);
  };

  useEffect(()=>{
    getOperations()
  },[])


  return (
    <div className="page-header">
      <Container className="">
        <Row>
          <Col className="align-left" lg={12}>
            <h2>
              {" "}
              <i class="fas fa-user-ninja"></i>Operations
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="align-right" lg={12}>
            <button
              className="btn-add"
              onClick={() => {
                props.history.push("/create_account");
              }}
            >
              Add Operation
            </button>
          </Col>
        </Row>
        <Row>
          {data.length !== 0 ? (
            data.map((operation,index) => {
              return (
                <Col key={operation.id} lg={4} md={6} sm={12}>
                  <div className="Card">
                    
                    <p>{operation.full_name + " " +  operation.last_name}</p>
                    <p>{operation.address}</p>
                    <p>{operation.email}</p>
                    <p>{operation.city}</p>

                    {/* <button
                    className="btn-card"
                      onClick={() => {
                        props.history.push(`/trainer/${trainer.id}`);
                      }}
                    >
                      View Trainer
                    </button> */}
                    <p>
                      <i
                        onClick={() => {
                          props.history.push(`/edit_operation/${operation.id}`);
                        }}
                        className="fas fa-edit pr-3"
                      ></i>
                      <i id={operation.id} onClick={deleting} className="fas fa-trash"></i>
                      
                    </p>
                  </div>
                </Col>
               )
            })
          ) : (
            <h4 className="text">No Operations Yet</h4>
          )}
        </Row>

        <Row>
          <Col className="align-left" lg={6}>
            {data.length !== 0 ? (
              <Pagination
                onChange={onChange}
                defaultCurrent={current}
                total={count}
                current={current}
              />
            ) : null}
          </Col>
        </Row>
        <Modal
          className="text-center requestsModel p-3"
          show={show}
          onHide={() => {
            setShow(false);
          }}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <h1 style={{ fontSize: "18px", color: "white" }}>
                  Are you sure you want to delete?{" "}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <button onClick={onDelete} className="requestsBtn mr-2">
                  Delete
                </button>
                <button
                  onClick={() => {
                    setShow(false);
                  }}
                  className="requestsBtn"
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </Container>
        </Modal>
      </Container>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default ErrorHandler(Operations, axiosApiInstance);
