import React, { Component } from "react";
import axios from "axios";
import { APILINK } from "../EndPoint";
import ErrorHandler from "../error-handler/ErrorHandler";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Checkbox, Select, DatePicker } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from 'moment';

const axiosApiInstance = axios.create();

class EditOperation extends Component {
    state = {
        fname: "",
        lname: "",
        email: "",
        address: "",
        birthDate: "",
        country: "",
        city: "",
        mobile: "",
        gender: "",
        selectedCountry: "",
        selectedCity: "",
        selectedGender: "",
        password : ""
    };
    getDetails = async () => {
        let result = await axiosApiInstance
            .get(APILINK + `/edit_profile/${this.props.match.params.id}`)
            .catch((err) => {
                console.log(err);
            });
        if (result) {
            console.log(result);
            this.setState({
                fname: result.data.full_name,
                email: result.data.email,
                lname: result.data.last_name,
                mobile: result.data.mobile,
                address: result.data.address,
                birthDate: result.data.birth_date,
                selectedCountry: result.data.country,
                selectedCity: result.data.city,
            });
        }
    };
    componentDidMount() {
        this.getDetails();
    }

    handleChange = (e) => {
        this.setState({ [e.target.name]: e.target.value });
    };


    edit = async () => {
        let result
        let data
        if(this.state.password !== ""){
            data = {
                full_name: this.state.fname,
                last_name: this.state.lname,
                email: this.state.email,
                mobile: this.state.mobile,
                gender : this.state.selectedGender,
                password : this.state.password,
                address : this.state.address,
                country : this.state.selectedCountry,
                city : this.state.selectedCity,
                birth_date : this.state.birthDate
            };
        }
        else{
            data = {
                full_name: this.state.fname,
                last_name: this.state.lname,
                email: this.state.email,
                mobile: this.state.mobile,
                gender : this.state.selectedGender,
                address : this.state.address,
                country : this.state.selectedCountry,
                city : this.state.selectedCity,
                birth_date : this.state.birthDate
            };
        }
      

        result = await axiosApiInstance.put(
            APILINK + `/update_operation/${this.props.match.params.id}`,
            data
        );

        console.log(data);

        console.log(result);
        if (result) {
                setTimeout(() => {
                    toast.info("Operation info has been updated successfully");
                }, 500);
                setTimeout(() => {
                    this.props.history.push(`/operations`);
                }, 2000);
        }
    };

    handleGender = (e) => {
        console.log(e)
        this.setState({ selectedGender: e })
    }
    handleCountry = (e) => {
        this.setState({ selectedCountry: e })
    }
    handleCity = (e) => {
        this.setState({ selectedCity: e })
    }

    dateHandler = (date, dateString) => {
        console.log(date, dateString);
        this.setState({birthDate : dateString})
      }
    render() {
        return (
            <div className="page-header">
                <Container>
                    <Form id="infoForm">
                        <Row>

                            <Col sm={12} md={6}>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>Full Name</Form.Label>
                                    <Form.Control
                                        name="fname"
                                        value={this.state.fname}
                                        onChange={this.handleChange}
                                        type="text"
                                        placeholder="Full Name"
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        name="lname"
                                        value={this.state.lname}
                                        onChange={this.handleChange}
                                        type="text"
                                        placeholder="last name"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>Address</Form.Label>
                                    <Form.Control
                                        name="lname"
                                        value={this.state.address}
                                        onChange={this.handleChange}
                                        type="text"
                                        placeholder="Address"
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Label>Email</Form.Label>

                                <Form.Control
                                    value={this.state.email}
                                    onChange={this.handleChange}
                                    placeholder="email"
                                    name="email"
                                    type="email"
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col sm={12} md={6}>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>Mobile</Form.Label>
                                    <Form.Control
                                        name="mobile"
                                        value={this.state.mobile}
                                        onChange={this.handleChange}
                                        type="number"
                                        placeholder="Mobile"
                                    />
                                </Form.Group>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Label>Gender</Form.Label>

                                <Select
                                    allowClear
                                    placeholder="Gender"
                                    value={this.state.selectedGender}
                                    onChange={this.handleGender}
                                    style={{ width: "100%" }}
                                >
                                    <Select.Option value="1">Male</Select.Option>
                                    <Select.Option value="2">Female</Select.Option>
                                </Select>
                            </Col>
                        </Row>
                        <Row>
                            <Col className="mb-3" sm={12} md={6}>
                                <Form.Label> City</Form.Label>

                                <Select
                                    allowClear
                                    placeholder="City"
                                    value={this.state.selectedCity}
                                    onChange={this.handleCity}
                                    style={{ width: "100%" }}
                                >
                                    <Select.Option key="1" value="cairo">Cairo</Select.Option>
                                    <Select.Option key="2" value="giza">Giza</Select.Option>
                                </Select>
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Label> Country</Form.Label>

                                <Select
                                    allowClear
                                    placeholder="Country"
                                    value={this.state.selectedCountry}
                                    onChange={this.handleCountry}
                                    style={{ width: "100%" }}
                                >
                                    <Select.Option key="1" value="egypt">Egypt</Select.Option>
                                    <Select.Option key="2" value="UAE">UAE</Select.Option>
                                    <Select.Option key="3" value="KSA">KSA</Select.Option>
                                </Select>
                            </Col>
                        </Row>
 
                        <Row>

                            <Col sm={12} md={6}>
                                <Form.Label>Birth Date</Form.Label>
                                <DatePicker format='YYYY-MM-DD' onChange={this.dateHandler} value={moment(this.state.birthDate,'YYYY-MM-DD')} className="w-100" />
                            </Col>
                            <Col sm={12} md={6}>
                                <Form.Group controlId="exampleForm.ControlInput1">
                                    <Form.Label>Password</Form.Label>
                                    <Form.Control
                                        name="password"
                                        value={this.state.password}
                                        onChange={this.handleChange}
                                        type="password"
                                    />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            {" "}
                            <Col sm={12}>
                                <Button onClick={this.edit} className="submit-btn-add">
                                    Edit
                                </Button>
                            </Col>
                        </Row>
                    </Form>
                </Container>
                <ToastContainer position="bottom-right" />
            </div>
        );
    }
}

export default ErrorHandler(EditOperation, axiosApiInstance);
