import React, { useEffect, useState } from "react";
import ReactDOM from 'react-dom';
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Button, Input } from "antd";
import 'draft-js/dist/Draft.css';
import RichTextEditor from "./richTextComponent";
import '../../css/draft.css'


const axiosApiInstance = axios.create();

const CreateArticle = (props) => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [count, setCount] = useState(1);
    const [current, setCurrent] = useState(1);
    const [title, setTitle] = useState("")
    const [image, setImage] = useState("")
    const [imageName, setImageName] = useState("")
    const [description , setDescription] = useState("")

    const onCreate = async () => {
        
        let result = await axiosApiInstance.post(APILINK + `/articles`,{
            image : image,
            image_name : imageName,
            title : title,
            description : description
        })  

        if (result) {
            setTimeout(() => {
              toast.info("Article has been created successfully");
            }, 500);
            setTimeout(() => {
              props.history.push(`/articles`);
            }, 2000);
          }

    }

const getText=(e)=>
{
    setDescription(e)
    console.log(e)
}

    return (
        <div className="page-header">
            <Container className="">
                <Row>
                    <Col className="align-left" lg={12}>
                        <h2>
                            {" "}
                            <i className="fas fa-user pr-3"></i>Create Article
                        </h2>
                    </Col>
                </Row>
               
                <Row className="mt-4">
                    <Col lg={6}>
                        <label className="label">Title</label>

                        <Input type="text" maxLength="50" value={title} onChange={(e) => setTitle(e.target.value)} />
                    </Col>
                    <Col lg={6}>
                        <label className="label">Image</label>

                        <Input type="file" name={imageName} onChange={(e) => {
                            let reader = new FileReader();
                            setImageName(e.target.files[0].name)
                            reader.readAsDataURL(e.target.files[0]);
                            reader.onload = (e) => {    
                            setImage(e.target.result)
                            }
                        }} />
                    </Col>
                </Row>
                <Row>
                    <Col lg={6}>
                        <label className="label">Description</label>

                        <RichTextEditor getText={getText} />
                    </Col>
                </Row>

                <Row>
                    <Col lg={12}>
                        <button className="submit-btn-add" onClick={onCreate}>Create</button>
                    </Col>
                </Row>
            </Container>
            <ToastContainer position="bottom-right" />

        </div>
    );
};

export default ErrorHandler(CreateArticle, axiosApiInstance);
