import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import {
  Container,
  Row,
  Col,
  Table,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";

const axiosApiInstance = axios.create();

const Branch = (props) => {
  const [data, setData] = useState([]);

  const getBranches = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/branch_update/${props.match.params.id}`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result.data);
      setData(result.data);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getBranches();
  }, []);
  return (
    <div className="page-header">
      <Container>
        <div className="branch-details">
          <Row>
            <Col lg={12}>
              <h2>
                {" "}
                <i class="fas fa-code-branch pr-2"></i> Branch Info
              </h2>
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {data ? (
                <ul>
                  <li>
                    <strong>Title : </strong>
                    {data.title}
                  </li>
                  <li>
                    <strong>Address : </strong>
                    {data.address}
                  </li>
                  <li>
                    <strong>Email : </strong>
                    {data.email}
                  </li>
                  <li>
                    <strong>Phone : </strong>
                    {data.phone}
                  </li>
                  <li>
                    <strong>Open : </strong>
                    {data.opening}
                  </li>
                  <li>
                    <strong>Close : </strong>
                    {data.closing}
                  </li>
                  <li>
                  <strong>Description : </strong>
                    {data.description}
                  </li>
                </ul>
              ) : null}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ErrorHandler(Branch, axiosApiInstance);
