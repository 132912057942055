import React, { useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";

const axiosApiInstance = axios.create();

const AddPackage = (props) => {


  const { register, handleSubmit, formState: { errors }, watch, setValue } = useForm();
  const packageType = watch('package_type')
  const expirationType = watch('expiration_type')

  const handlePackage = (e) => {
    setValue('package_type', e.target.value)
  }

  const handleExpiration = (e) => {
    setValue('expiration_type', e.target.value)
  }


  const onSubmit = async (data) => {
    if (packageType == "1") {
      data.package_type = packageType;
      data.expiration_type = expirationType;

    } else {
      data.branch_id = props.match.params.id;
      data.package_type = packageType;
      data.expiration_type = expirationType;
    }

    console.log(data);

    let result = await axiosApiInstance.post(APILINK + `/package`, data);

    if (result) {
      setTimeout(() => {
        toast.info("Package has been created successfully");
      }, 500);
      setTimeout(() => {
        props.history.push(`/packages/${props.match.params.id}`);
      }, 2000);
    }
  };

  return (
    <div className="page-header">
      <Container>
        <Row>
          <Col lg={12}>
            <h2>Add Package</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <React.Fragment>
                <Row>
                  <Col lg={6}>
                    <label className="label">Package Type</label>
                    <select
                      onChange={handlePackage}
                      value={packageType}
                      className="w-100"
                      {...register("package_type", { required: true })}
                    >
                      <option value="" selected hidden disabled>Package Type</option>

                      <option value="1">Revolt Live</option>
                      <option value="2"> Group training</option>
                      <option value="3"> Gym Membership</option>
                    </select>
                    <p className="error">
                      {errors.package_type?.type === "required" &&
                        "This field is required"}
                    </p>

                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">Title</label>
                    <Input
                      type="text"
                      {...register("title", { required: true })}
                    />
                    <p className="error">
                      {errors.title?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Price</label>
                    <Input
                      type="number"
                      {...register("price", { required: true , min : 0 })}
                    />
                    <p className="error">
                      {errors.price?.type === "required" &&
                        "This field is required"}
                    </p>
                    <p className="error">
                      {errors.price?.type === "min" &&
                        "Minimum value is zero"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label"> Expiration Type</label>
                    <select
                      name="expiration_type"
                      onChange={handleExpiration}
                      value={expirationType}
                      className="w-100"
                      {...register("expiration_type", { required: true })}

                    >
                      <option value="" selected hidden disabled>Expiration Type</option>

                      <option value="1">Monthly</option>
                      <option value="2">Annually</option>
                    </select>

                    <p className="error">
                      {errors.expiration_type?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Description</label>
                    <Input
                      type="text"
                      {...register("description", { required: true })}
                    />
                    <p className="error">
                      {errors.description?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  {packageType !== "3" ? (
                    <Col lg={6}>
                      <label className="label">Session Limit</label>
                      <Input
                        type="number"
                        {...register("session_limit", { required: packageType !== "3" , min : 0})}
                      />
                      <p className="error">
                        {errors.session_limit?.type === "required" &&
                          "This field is required"}
                      </p>
                      <p className="error">
                      {errors.price?.type === "min" &&
                        "Minimum value is zero"}
                    </p>
                    </Col>
                  ) : null}
                  {expirationType === "1" ? (
                    <Col lg={6}>
                      <label className="label">Month Number</label>
                      <Input
                        type="number"
                        {...register("month_count", { required: expirationType === "1" })}
                      />
                      <p className="error">
                        {errors.month_count?.type === "required" &&
                          "This field is required"}
                      </p>
                    </Col>
                  ) : null}
                </Row>
              </React.Fragment>

              <input className="submit-btn-add" value="Add" type="submit" />
            </form>
          </Col>
        </Row>

        <ToastContainer position="bottom-right" />
      </Container>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  privilege: state.auth.privilege,
});
export default connect(mapStateToProps)(
  ErrorHandler(AddPackage, axiosApiInstance)
);
