import React, { Component } from "react";
import { Container, Row, Col } from "react-bootstrap";
import { Form, Input, Button } from "antd";
import "antd/dist/antd.css";
import GoogleLogin from "react-google-login";
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

//redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { userLogin, LogOut } from "../global-state/actions/authAction";
import { showNav } from "../global-state/actions/showNavAction";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// import bcrypt from "bcrypt"
const saltRounds = 10;

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      BeErr: "",
      disabled : false
    };
  }
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onLogin = async (e) => {
    let loginrequest = {};
    // const salt = bcrypt.genSaltSync(saltRounds);
    // const hash = bcrypt.hashSync(this.state.password, salt);
    loginrequest.email = this.state.email;
    loginrequest.password = this.state.password;
    this.setState({disabled : true})
    const userData = await this.props.userLogin(
      loginrequest,
      this.props.history
    );
    // console.log(userData);
    if (userData) {
      // this.setState({ BeErr: userData });
      setTimeout(() => {
        toast.error(userData);
      }, 500);
      this.setState({disabled : false})
    }
  };

  componentDidMount() {
    this.props.LogOut();
    this.props.showNav(false);
  }

  onFinish = (values) => {
    // console.log("Success:", values);
  };

  onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);
  };

  responseGoogle = (response) => {
    console.log(response);
  };
  responseFacebook = (response) => {
    console.log(response);
  };

  render() {
    return (
      <div className="page-header">
        {/* <Container className="p-4"></Container> */}
        <Container className="loginContainer">
          <Row className="login-fields-row">
            
            <Col className="text-left" sm={6}>
              <Form
                onFinish={this.onFinish}
                onFinishFailed={this.onFinishFailed}
                style={{
                  margin: "30px auto 0px auto",
                }}
                layout="vertical"
              >
                <h1 className="title1">Sign In</h1>

                <Form.Item
                  label="Email"
                  name="Email"
                  rules={[
                    { required: true, message: "Please input your Email!" },
                  ]}
                  layout="vertical"
                >
                  <Input
                    className="loginInput"
                    placeholder="Email"
                    onChange={this.handleChange}
                    type="email"
                    name="email"
                    value={this.state.email}
                  />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password
                    value={this.state.password}
                    name="password"
                    onChange={this.handleChange}
                    placeholder="Password"
                    className="loginInput"
                  />
                </Form.Item>
                <a  href="/forgot_password" className="forget">Forgot Password?</a>
                <Form.Item className="submit-btn">
                  <Button
                    disabled={this.state.disabled}
                    className="loginBtn1"
                    onClick={this.onLogin}
                    htmlType="submit"
                  >
                    LOGIN{" "}
                  </Button>
                </Form.Item>
                <Row>
                  <Col style={{ margin: "auto" }} sm={12}>
                    {this.state.BeErr !== "" ? (
                      <p style={{ color: "red", fontWeight: "bold" }}>
                        {this.state.BeErr}
                      </p>
                    ) : null}
                  </Col>
                </Row>
              
               
              </Form>
            </Col>
          </Row>
         
        
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ userLogin, LogOut, showNav }, dispatch);
};
export default connect(null, mapDispatchToProps)(Login);
