import React, { useEffect, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Pagination, Checkbox } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Trainer from "../cards/trainerCard";
const axiosApiInstance = axios.create();

const Trainers = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [count, setCount] = useState(1);
  const [show, setShow] = useState(false)
  const [selectedTrainer, setSelectedTrainer] = useState("")
  const [topTrainers, setTopTrainers] = useState({})
  const [topTrainersArray, setTopTrainersArray] = useState([])

  const getTrainers = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/trainer_signup?page=1`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result.data.data);
      setData(result.data.data);
      setCount(result.data.count);
    }
  };

  const deleting = (e) => {
    setSelectedTrainer(e.target.id)
    setShow(true)
  }
  const onDelete = async (e) => {
    let result = await axiosApiInstance.delete(
      APILINK + `/trainer_profile/${selectedTrainer}`
    );
    getTrainers();
    setShow(false);
  };


  const addTop = async (e) => {
    let result
    if (e.target.checked && topTrainersArray.length === 5) {
      setTimeout(() => {
        toast.info("There are already 5 top trainers");
      }, 500);
    }
    else if (e.target.checked && topTrainersArray.length < 5) {
      result = await axiosApiInstance.post(APILINK + `/top_trainers`, { trainer_id: e.target.id })
      if (result) {
        setTimeout(() => {
          toast.info("Trainer added to top trainers");
        }, 500);
      }
    }
    else {
      result = await axiosApiInstance.delete(APILINK + `/top_trainers/${e.target.id}`)
      if (result) {
        setTimeout(() => {
          toast.info("Trainer removed from top trainers");
        }, 500);
      }
    }
    getTopTrainers()

  }
  const onChange = async (page) => {
    console.log(page);
    setCurrent(page);
    let result = await axiosApiInstance.get(
      APILINK + `/trainer_signup?page=${page}`
    );
    setData(result.data.data);
    setCount(result.data.count);
  };

  const getTopTrainers = async () => {
    let result = await axiosApiInstance.get(APILINK + '/top_trainers').catch(err => {
      console.log(err)
    })

    if (result) {

      const topIDObject = {}
      const top = result.data.map(trainer => topIDObject[trainer.trainer.id] = "2")


      setTopTrainersArray(result.data)
      setTopTrainers(topIDObject)



    }
  }

  const checkBoxCheck = (index) => {

    if (data[index] && topTrainers.hasOwnProperty(data[index].id)) {
      return true
    }
    else {
      return false
    }
  }


  useEffect(() => {
    console.log(checkBoxCheck(3))

  }, [data])


  useEffect(() => {
    console.log(topTrainers)
  }, [topTrainers])


  useEffect(() => {
    window.scrollTo(0, 0);
    getTrainers();
    getTopTrainers()
  }, []);
  return (
    <div className="page-header">
      <Container className="">
        <Row>
          <Col className="align-left" lg={12}>
            <h2>
              {" "}
              <i class="fas fa-user-ninja"></i>Trainers
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="align-right" lg={12}>
            <button
              className="btn-add"
              onClick={() => {
                props.history.push("/add_trainer");
              }}
            >
              Add Trainer
            </button>
          </Col>
        </Row>
        <Row>
          {/* <Trainer  /> */}
          {data.length !== 0 ? (
            data.map((trainer, index) => {
              return (
                <Col key={trainer.id} lg={4} md={6} sm={12}>
                  <div className="Card">
                    <img
                      className="trainer-image"
                      src={APILINK + trainer.image}
                    />
                    <p><strong>{trainer.title}</strong></p>
                    <p>{trainer.address}</p>
                    <button
                      className="btn-card"
                      onClick={() => {
                        props.history.push(`/trainer/${trainer.id}`);
                      }}
                    >
                      View Trainer
                    </button>
                    <p>
                      <i
                        onClick={() => {
                          props.history.push(`/edit_trainer/${trainer.id}`);
                        }}
                        className="fas fa-edit pr-3"
                      ></i>
                      <i onClick={deleting} id={trainer.id} className="fas fa-trash"></i>
                      {/* <Checkbox className="seperate" checked={checkBoxCheck(index)} onChange={addTop} id={trainer.id}>Top Trainer? </Checkbox> */}
                      {/* <i className="fas fa-add" id={trainer.id} onClick={addTop}></i> */}
                    </p>
                  </div>
                </Col>
              );
            })
          ) : (
            <h4 className="text">No Trainers Yet</h4>
          )}
        </Row>

        <Row>
          <Col className="align-left" lg={6}>
            {data.length !== 0 ? (
              <Pagination
                onChange={onChange}
                defaultCurrent={current}
                total={count}
                current={current}
              />
            ) : null}
          </Col>
        </Row>
        <Modal
          className="text-center requestsModel p-3"
          show={show}
          onHide={() => {
            setShow(false);
          }}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <h1 style={{ fontSize: "18px", color: "white" }}>
                  Are you sure you want to delete?{" "}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <button onClick={onDelete} className="requestsBtn mr-2">
                  Delete
                </button>
                <button
                  onClick={() => {
                    setShow(false);
                  }}
                  className="requestsBtn"
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </Container>
        </Modal>
      </Container>
      <ToastContainer position="bottom-right" />
    </div>
  );
};

export default ErrorHandler(Trainers, axiosApiInstance);
