import React, { useEffect, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import { Pagination, Image } from "antd";

import "react-toastify/dist/ReactToastify.css";

const axiosApiInstance = axios.create();

const Articles = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [count, setCount] = useState(1);
  const [selectedArticle, setSelectedArticle] = useState("");
  const [show, setShow] = useState(false);

  const getArticles = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/articles`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      setData(result.data.data);
      setCount(result.data.count);
    }
  };
  const onChange = async (page) => {
    console.log(page);
    setCurrent(page);
    let result = await axiosApiInstance.get(
      APILINK + `/trainer_signup?page=${page}`
    );
    setData(result.data);
    setCount(result.data.count);
  };

  const onDelete = async (e) => {
    let result = await axiosApiInstance.delete(
      APILINK + `/articles/${selectedArticle}`
    );
    getArticles();
    setShow(false);
  };

  const deleting = (e) => {
    setSelectedArticle(e.target.id)
    setShow(true)
  }
  useEffect(() => {
    window.scrollTo(0, 0);
    getArticles();
  }, []);
  return (
    <div className="page-header">
      <Container className="">
        <Row>
          <Col className="align-left" lg={12}>
            <h2>
              {" "}
              <i class="fas fa-newspaper pr-2"></i>Articles
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="align-right" lg={12}>
            <button
              className="btn-add"
              onClick={() => {
                props.history.push("/add_article");
              }}
            >
              Add Article
            </button>
          </Col>
        </Row>
        <Row>
          {data.length !== 0 ? (
            data.map((article) => {
              return (
                <Col key={article.id} lg="3" md={4} sm={12}>
                  <div
                    id={article.id}

                    className="Card"
                  >
                    <Image
                      id={article.id}
                      className="announcement-image"
                      src={APILINK + "/media/" + article.image}
                    />
                    <a><h4 className="articleTitle" id={article.id} onClick={(e) => {
                      console.log(e.currentTarget.id);
                      props.history.push(`/article/${e.currentTarget.id}`);
                    }}>{article.title}</h4></a>
                    <p>
                      <i onClick={() => {
                        props.history.push(`/edit_article/${article.id}`);
                      }} style={{ color: "white" }} id={article.id} className="fas fa-edit"></i>

                      <i onClick={deleting} style={{ color: "white" }} id={article.id} className="fas fa-trash ml-2"></i>
                    </p>
                  </div>
                </Col>
              );
            })
          ) : (
            <h4 className="text">No Articles Yet</h4>
          )}
        </Row>

        <Row>
          <Col className="align-left" lg={6}>
            {data.length !== 0 ? (
              <Pagination
                onChange={onChange}
                defaultCurrent={current}
                total={count}
                current={current}
              />
            ) : null}
          </Col>
        </Row>
        <Modal
          className="text-center requestsModel p-3"
          show={show}
          onHide={() => {
            setShow(false);
          }}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <h1 style={{ fontSize: "18px", color: "white" }}>
                  Are you sure you want to delete?{" "}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <button onClick={onDelete} className="requestsBtn mr-2">
                  Delete
                </button>
                <button
                  onClick={() => {
                    setShow(false);
                  }}
                  className="requestsBtn"
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </Container>
        </Modal>
      </Container>
    </div>
  );
};

export default ErrorHandler(Articles, axiosApiInstance);
