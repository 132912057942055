import React, { Component } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Select } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/leads.css";
import { Progress } from "reactstrap";

const axiosApiInstance = axios.create();

class EditBranch extends Component {
  state = {
    title: "",
    price: "",
    des: "",
    limit: "",
    selectedExType: "",
    selectedType: "",
    month: "",
    imagesArray: [],
    branchImgs: [],
    description : ""
  };
  getDetails = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/branch_update/${this.props.match.params.id}`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result);
      this.setState({
        title: result.data.title,
        address: result.data.address,
        email: result.data.email,
        phone: result.data.phone,
        open: result.data.opening,
        close: result.data.closing,
        branchImgs: result.data.branch_images,
        description : result.data.description
      });
    }
  };
  componentDidMount() {
    this.getDetails();
  }

  edit = async () => {
    let data;

    data = {
      title: this.state.title,
      address: this.state.address,
      email: this.state.email,
      phone: this.state.phone,
      closing: this.state.close,
      opening: this.state.open,
      description : this.state.description
    };

    let result = await axiosApiInstance.put(
      APILINK + `/branch_update/${this.props.match.params.id}`,
      data
    );
    console.log(result);
    if (result) {
      if (result.data.id) {
        setTimeout(() => {
          toast.info("Branch has been updated successfully");
        }, 500);
        setTimeout(() => {
          this.props.history.push(`/branches`);
        }, 2000);
      }
    }
  };
  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  onImgChange = async (e) => {
    let formData = new FormData();
    formData.append("image", e.target.files[0]);
    let result = await axiosApiInstance.put(
      APILINK + `/branch_images/${e.target.id}`,
      formData
    );
    console.log(result);
    if (result) {
      if (result.data.image);
      {
        setTimeout(() => {
          toast.info("Branch Image has been updated successfully");
        }, 500);
        this.getDetails();
      }
    }
  };
  render() {
    return (
      <div className="page-header">
        <Container>
          <Form id="infoForm">
            <Row>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Title"
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Address</Form.Label>
                  <Form.Control
                    name="address"
                    value={this.state.address}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Address"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    name="email"
                    value={this.state.email}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Email"
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Phone</Form.Label>
                  <Form.Control
                    name="phone"
                    value={this.state.phone}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Phone"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-3">
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Open at</Form.Label>
                  <Form.Control
                    name="open"
                    value={this.state.open}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Open at"
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Close at</Form.Label>
                  <Form.Control
                    name="close"
                    value={this.state.close}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Close at"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
            <Col sm={12} md={6}>
                <Form.Label>Description</Form.Label>

                <Form.Control
                  value={this.state.description}
                  onChange={this.handleChange}
                  placeholder="Descripion"
                  name="description"
                  as="textarea"
                  rows={3}
                />
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm={12}>
                <Button onClick={this.edit} className="submit-btn-add">
                  Edit
                </Button>
              </Col>
            </Row>
            <Row>
              {this.state.branchImgs.length !== 0
                ? this.state.branchImgs.map((img) => {
                    return (
                      <Col sm="12" md="3">
                        <div className="branchImgDiv">
                          <img
                            src={APILINK + img.image}
                            className="img-fluid branchImg"
                          />

                          <div className="overlay">
                            <p>Update Photo</p>

                            <input
                              className="updateImg"
                              type="file"
                              onChange={this.onImgChange}
                              id={img.pk}
                            />
                          </div>
                        </div>
                      </Col>
                    );
                  })
                : null}
            </Row>
          </Form>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

export default ErrorHandler(EditBranch, axiosApiInstance);
