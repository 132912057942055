import React, { useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { Input } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { connect } from "react-redux";
import { Checkbox, DatePicker } from "antd";

const axiosApiInstance = axios.create();

const AddPromo = (props) => {
  const [selectedType, setType] = useState("0");
  const [redeemCheck, setRedeem] = useState(false);
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm();

  const type = watch('discount_type')

  const getChangeHandlerWithValue = (name) => (value, val2) => {
    setValue(name, val2);
  };

  const handleType = (e) =>{
    setValue('discount_type', e.target.value)
  }
  const onSubmit = async (data) => {
    // data.discount_type = selectedType;
    data.redeem_once = redeemCheck;
    if (type == "1") {
      data.discount_type = true;
    } else {
      data.discount_type = false;
    }
    console.log(data);

    let result = await axiosApiInstance
      .post(APILINK + `/promo_code`, data)
      .catch((err) => {
        console.log(err.response);
        setTimeout(() => {
          toast.error(err.response.data.code[0]);
        }, 500);
      });

    if (result) {
      setTimeout(() => {
        toast.info("Promo code has been created successfully");
      }, 500);
      setTimeout(() => {
        props.history.push(`/promo_codes`);
      }, 2000);
    }
  };
  const handleRedeem = (e) => {
    setRedeem(e.target.checked);
  };
  return (
    <div className="page-header">
      <Container>
        <Row>
          <Col lg={12}>
            <h2>Add Promo Code</h2>
            <form onSubmit={handleSubmit(onSubmit)}>
              <React.Fragment>
                <Row>
                  <Col lg={6}>
                    <label className="label">Code</label>
                    <Input
                      type="text"
                      // className={errors.code ? "field-error" : ""}
                      {...register("code", { required: true })}
                    />
                    <p className="error">
                      {errors.code?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col lg={6}>
                    <label className="label">Discount</label>
                    <Input
                      type="number"
                      {...register("discount", { required: true ,max : type === "1" ? 100 : 9999})}
                    />
                    <p className="error">
                      {errors.discount?.type === "required" &&
                        "This field is required"}
                    </p>
                    <p className="error">
                      {errors.discount?.type === "max" &&
                        "Maximum value exceeded"}
                    </p>
                  </Col>
                </Row>
                <Row>
                  <Col lg={6}>
                    <label className="label">Discount Type</label>
                    <select
                      {...register('discount_type', { required: true })}
                      onChange={handleType}
                      value={type}
                      className="w-100"
                    >
                      <option value="" selected hidden disabled>
                        Discount Type
                      </option>

                      <option value="1">Percentage</option>
                      <option value="2"> Money</option>
                    </select>
                    <p className="error">
                      {errors.discount_type?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                  <Col className="mt-5" lg={6}>
                    <Checkbox onChange={handleRedeem}>Redeem Once?</Checkbox>
                  </Col>
                </Row>
                <Row>
                  <Col className='mt-4' lg={6}>
                    <label className="label">End Date</label>

                    <DatePicker
                      {...register("end_date", { required: true })}
                      className="w-100"
                      onChange={getChangeHandlerWithValue("end_date")}
                    />
                    <p className="error">
                      {errors.end_date?.type === "required" &&
                        "This field is required"}
                    </p>
                  </Col>
                </Row>
              </React.Fragment>

              <input className="submit-btn-add" value="Add" type="submit" />
            </form>
          </Col>
        </Row>

        <ToastContainer position="bottom-right" />
      </Container>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.auth.user,
  privilege: state.auth.privilege,
});
export default connect(mapStateToProps)(
  ErrorHandler(AddPromo, axiosApiInstance)
);
