import React, { Component } from "react";
import "../../css/AuthHeader.css";
import { Link, NavLink } from "react-router-dom";
import { Navbar, Nav, NavDropdown, Container } from "react-bootstrap";
import { connect } from "react-redux";
import logo from "../../images/logo.svg";
//redux
import { bindActionCreators } from "redux";
import { LogOut } from "../../global-state/actions/authAction";

class Header extends Component {
  state = {};
  logout = (e) => {
    this.props.LogOut();
    window.location = "/admin-login";
  };
  render() {
    return (
      <div>
        <Navbar bg="dark" expand="lg">
          <Navbar.Brand href="#home">
            <img className="header-logo" src={logo} alt="Logo" />
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav>
              <Nav.Link className="admin-name">
                Hello{" "}
                {this.props.user.full_name === "" ? (
                  "Admin"
                ) : (
                  <strong>{this.props.user.full_name}</strong>
                )}{" "}
              </Nav.Link>
            </Nav>
            <Nav className="ml-auto">
              {this.props.user.type === "4" ? (
                <NavDropdown title="" id="basic-nav-dropdown">
                  <NavDropdown.Item href="/create_account">
                    Create Operation Account
                  </NavDropdown.Item>
                </NavDropdown>
              ) : null}

              <Nav.Link onClick={this.logout}>
                {" "}
              V1.0.1  <i class="logout-icon fas fa-sign-out-alt pr-2"></i>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Navbar>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  user: state.auth.user,
  showCalender: state.showCalender.showCalender,
});
const mapDispatchToProps = (dispatch) => {
  return bindActionCreators({ LogOut }, dispatch);
};
export default connect(mapStateToProps, mapDispatchToProps)(Header);
