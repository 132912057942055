import React, { useEffect, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Modal } from "react-bootstrap";
import { Spin, Pagination } from "antd";

import "react-toastify/dist/ReactToastify.css";

const axiosApiInstance = axios.create();

const ViewCodes = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [count, setCount] = useState(1);
  const [show, setShow] = useState(false);
  const [selectedCode, setCodeID] = useState(false);

  const getCodes = async () => {
    setLoading(true);
    let result = await axiosApiInstance
      .get(APILINK + `/promo_code?page=1`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result.data);
      setData(result.data.data);
      setCount(result.data.count);
      setLoading(false);
    }
  };
  const onChange = async (page) => {
    console.log(page);
    setCurrent(page);
    let result = await axiosApiInstance.get(
      APILINK + `/promo_code?page=${page}`
    );
    setData(result.data.data);
    setCount(result.data.count);
  };
  useEffect(() => {
    window.scrollTo(0, 0);
    getCodes();
  }, []);
  const deleting = (e) => {
    setCodeID(e.target.id);
    setShow(true);
  };
  const onDelete = async (e) => {
    let result = await axiosApiInstance.delete(
      APILINK + `/promo_code/${selectedCode}`
    );
    getCodes();
    setShow(false);
  };
  return (
    <div className="page-header">
      <Container className="">
        <Row>
          <Col className="align-left" lg={12}>
            <h2>
              {" "}
              <i class="fas fa-tags pr-2"></i>Promo Codes
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="align-right" lg={12}>
            <button
              className="btn-add"
              onClick={() => {
                props.history.push("/add_code");
              }}
            >
              Add Promo code
            </button>
          </Col>
        </Row>
        <Row>
          {data.length !== 0 && loading == false ? (
            data.map((code) => {
              return (
                <Col lg={4} md={6} sm={12}>
                  <div className="Card promocode">
                    <h5>{code.code}</h5>
                    <p>{parseInt(code.discount).toFixed(2)}</p>
                    <p>
                      {code.start_date.split("T")[0]} -{" "}
                      {code.end_date.split("T")[0]}
                    </p>
                    {code.redeem_once ? <p>Redeem Once</p> : null}
                    <p>
                      {code.discount_type ? (
                        <p>percentage Discount</p>
                      ) : (
                        <p>Money Discount</p>
                      )}
                      <i
                        onClick={() => {
                          props.history.push(`/edit_code/${code.code}`);
                        }}
                        className="fas fa-edit pr-3"
                      ></i>
                      <i
                        id={code.code}
                        onClick={deleting}
                        className="fas fa-trash pr-3"
                      ></i>
                      {/* <i  id={branch.id} className="fas fa-trash"></i> */}
                    </p>
                  </div>
                </Col>
              );
            })
          ) : loading && data.length == 0 ? (
            <Spin tip="...loading" />
          ) : (
            <h4 className="text">No Codes Yet</h4>
          )}
        </Row>

        <Row>
          <Col className="align-left" lg={6}>
            {data.length !== 0 ? (
              <Pagination
                onChange={onChange}
                defaultCurrent={current}
                total={count}
                current={current}
              />
            ) : null}
          </Col>
        </Row>
        <Modal
          className="text-center requestsModel p-3"
          show={show}
          onHide={() => {
            setShow(false);
          }}
        >
          <Container className="py-4">
            <Row>
              <Col sm="12">
                <h1 style={{ fontSize: "18px", color: "white" }}>
                  Are you sure you want to delete?{" "}
                </h1>
              </Col>
            </Row>
            <Row>
              <Col sm={12}>
                <button onClick={onDelete} className="requestsBtn mr-2">
                  Delete
                </button>
                <button
                  onClick={() => {
                    setShow(false);
                  }}
                  className="requestsBtn"
                >
                  Cancel
                </button>
              </Col>
            </Row>
          </Container>
        </Modal>
      </Container>
    </div>
  );
};

export default ErrorHandler(ViewCodes, axiosApiInstance);
