import "./App.css";
import React,{useEffect} from "react";
import 'babel-polyfill';
import { BrowserRouter as Router, Route ,Switch} from "react-router-dom";
import { connect } from "react-redux";
import Leads from "./components/leads";
import SideMenu from "./components/layout/sidemenu";
import Header from "./components/layout/Header";
import Login from "./components/Login";
import Branches from "./components/Branch/branches";
import Branch from "./components/Branch/branch";
import AddBranch from "./components/Branch/addBranch";
import Trainers from "./components/trainers/trainers";
import Trainer from "./components/trainers/trainer";
import AddTrainer from "./components/trainers/addTrainer";
import Packages from "./components/package/packages";
import AddPackage from "./components/package/addPackage";
import EditPackage from "./components/package/EditPackage";
import ViewClients from "./components/Clients/ViewClients";
import EditTrainer from "./components/trainers/EditTrainer";
import ClientsRequests from "./components/operations/ClientsRequests";
import Sessions from "./components/sessions/allSessions";
import AddSession from "./components/sessions/addSession";
import EditSession from "./components/sessions/editSession";
import CreateOperation from "./components/createOperation";
import AdminClientsRequests from "./components/Clients/clientsRequests";
import ViewAnnouncements from "./components/Announcements/viewAnnouncements";
import AddAnnouncement from "./components/Announcements/addAnnouncment";
import CreateArticle from "./components/articles/createArticle";
import Articles from "./components/articles/articles";
import Article from "./components/articles/article";
import EditBranch from "./components/Branch/EditBranch";
import ViewCodes from "./components/Promocodes/ViewCodes";
import AddPromo from "./components/Promocodes/AddPromo";
import EditCode from "./components/Promocodes/EditCode";
import Payments from "./components/Payments";
import EditArticle from "./components/articles/editArticle";
import Operations from "./components/operations";
import EditOperation from "./components/editOperation";
import UnAuthorized from "./components/unAuthorized";
import Client from "./components/Clients/client";
import ForgotPassword from "./components/forgotPassword";
// import { getFToken } from "./firebase";

const App = (props) => {
  useEffect(() => {
    // getFToken()
  }, [])
  return (
    <Router>
      {props.isAuth ? (
        <React.Fragment>
          <Header />
          <SideMenu />
          {props.user.user_type == "4" ? (
            <Route exact path="/home" component={AdminClientsRequests} />
          ) : props.user.user_type == "3" ?(
            <Route exact path="/home" component={ClientsRequests} />
          ):null}
          <Route exact path="/branches" component={Branches} />
          <Route exact path="/add_branch" component={AddBranch} />
          <Route exact path="/branch/:id" component={Branch} />
          <Route exact path="/trainers" component={Trainers} />
          <Route exact path="/trainer/:id" component={Trainer} />
          <Route exact path="/add_trainer" component={AddTrainer} />
          <Route exact path="/packages/:id" component={Packages} />
          <Route exact path="/add_package/:id" component={AddPackage} />
          <Route exact path="/edit_package/:id" component={EditPackage} />
          <Route exact path="/clients" component={ViewClients} />
          <Route exact path="/edit_trainer/:id" component={EditTrainer} />
          <Route exact path="/sessions" component={Sessions} />
          <Route exact path="/add_session" component={AddSession} />
          <Route exact path="/edit_session/:id" component={EditSession} />
          <Route exact path="/create_account" component={CreateOperation} />
          <Route exact path="/operations" component={Operations} />
          <Route exact path="/edit_operation/:id" component={EditOperation} /> 
          <Route exact path="/announcements" component={ViewAnnouncements} />
          <Route exact path="/add_announcement" component={AddAnnouncement} />
          <Route exact path="/create_article" component={CreateArticle} />
          <Route exact path="/articles" component={Articles} />
          <Route exact path="/article/:id" component={Article} />
          <Route exact path="/add_article" component={CreateArticle} />
          <Route exact path="/edit_branch/:id" component={EditBranch} />
          <Route exact path="/promo_codes" component={ViewCodes} />
          <Route exact path="/add_code" component={AddPromo} />
          <Route exact path="/edit_code/:id" component={EditCode} />
          <Route exact path="/payments" component={Payments} />
          <Route exact path="/edit_article/:id" component={EditArticle} />
          <Route exact path="/client/:id" component={Client} />

        </React.Fragment>
      ) : (
        <React.Fragment>
          {" "}
          <Switch>
            <Route exact path="/" component={Leads} />
            <Route exact path="/admin-login" component={Login} />
            <Route exact path="/forgot_password" component={ForgotPassword} />
            <Route path="/:route" component={Login} />

          </Switch>
        </React.Fragment>
      )}
    </Router>
  );
};
const mapStateToProps = (state) => ({
  isAuth: state.auth.isAuth,
  showNav: state.showNav.showNav,
  user: state.auth.user,
});
export default connect(mapStateToProps)(App);
