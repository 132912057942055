import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col } from "react-bootstrap";
import { Pagination, Calendar, Badge, Alert } from "antd";
import "react-toastify/dist/ReactToastify.css";
import DaysSessions from "./daysSessions";
import moment from "moment";

const axiosApiInstance = axios.create();

const Sessions = (props) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [current, setCurrent] = useState(1);
  const [count, setCount] = useState(1);
  const [value, setValue] = useState(moment());
  const [selectedValue, setSelectedValue] = useState(moment());
  const [selectedDateID, setSelectedDateID] = useState("");
  const [daysData, setDaysData] = useState([]);
  const [eventDays, setEventDays] = useState([]);
  const [daysEventsArray, setDays] = useState([]);
  const [updated, setUpdated] = useState(false);
  const [selectedDay , setSelectedDay] = useState("")
  useEffect(() => {
    try {
      const getEventDays = async () => {
        let element = document.getElementsByClassName(
          "ant-picker-calendar-date"
        );
        let ElementList = [...element];
        ElementList.map((element) => {
          element.classList.remove("selectedEvents");
        });
        const result = await axiosApiInstance.get(
          APILINK +
            `/session_month?month=${selectedValue.format("YYYY-MM-DD").split("-")[1]}`
        );

        console.log(result.data.data);
        setDaysData(result.data.data);

        if (result) {
          console.log(result)
          const eventsDays = [];
          let f = result.data.data.map((date) => {
            console.log(date);
            let char = date.split("-")[2];
            if (char[0] == "0") {
              char = char[1];
            }
            eventsDays.push(parseInt(char));
          });
          setEventDays(eventsDays);
        }

       let events = document.getElementsByClassName("events");
        element = document.getElementsByClassName("ant-picker-calendar-date");
      let   eventsList = [...events];
         ElementList = [...element];
        // console.log(ElementList);
        eventsList.map((child, index) => {
          // console.log(child.childNodes);
          if (child.childNodes.length !== 0) {
            // console.log(ElementList[index]);
            ElementList[index].className += " selectedEvents";
          }
        });
      };
      getEventDays();
    } catch (err) {
      console.log(err);
    }
  }, [selectedValue, updated]);

  const onSelect = async (value) => {
    console.log(value);
    // setValue(value);
    // setSelectedValue(value);
    setSelectedDay(value.format("YYYY-MM-DD"))
    setLoading(true)
    // console.log(data);
    console.log(value.format("YYYY-MM-DD"));
    let result = await axiosApiInstance.get(APILINK +`/session?date=${value.format("YYYY-MM-DD")}`)
    if(result){
      setLoading(false)
      console.log(result)
      setDays(result.data.data)
    }
   
  };

  
  const onChange = async (page) => {
    console.log(page);
    setCurrent(page);
    let result = await axiosApiInstance.get(APILINK + `/session?data=${selectedDay}&page=${page}`);
    setDays(result.data.data);
    setCount(result.data.count);
    
  };
  const getListData = (value) => {
    let listData = [];
    // console.log(value)
    // console.log(value.date())

    let t = moment(value._d)
      .format("YY-MM-DD")
      .substring(0, 5);
    let curr_t = selectedValue;

    curr_t = moment(curr_t)
      .format("YY-MM-DD")
      .substring(0, 5);

    if (eventDays && t === curr_t) {
      // console.log(eventDays)
      if (eventDays.includes(value.date())) {
        listData = [
          {
            type: "success",
            content: "Action needed",
          },
        ];
      }
    }
    return listData || [];
  };
  const onPanelChange = (value) => {
    setValue(value);
  };

  const dateCellRender = (value) => {
    const listData = getListData(value);
    // console.log(listData);

    return (
      <ul className="events">
        {listData.map((item) => (
          <li style={{ listStyle: "none" }} key={item.content}>
            {/* <Badge status={item.type} text={item.content} /> */}
          </li>
        ))}
      </ul>
    );
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div className="page-header">
      <div className="sessionContainer">
        <Row>
          <Col className="align-left" lg={12}>
            <h2>
              {" "}
              <i class="fas fa-calendar-alt pr-2"></i>Sessions
            </h2>
          </Col>
        </Row>
        <Row>
          <Col className="align-right" lg={12}>
            <button
              className="btn-add"
              onClick={() => {
                props.history.push("/add_session");
              }}
            >
              Add Session
            </button>
          </Col>
        </Row>

        <Row className='sessions'> 
          <Col lg={12}>
            <Alert
              message={`You selected date: ${selectedValue &&
                selectedValue.format("YYYY-MM-DD")}`}
            />
            <Calendar
              value={value}
              onSelect={onSelect}
              onPanelChange={onPanelChange}
              dateCellRender={dateCellRender}
              // monthCellRender={this.monthCellRender}
              fullscreen={false}
            />
          </Col>
        </Row>
          <Row>

          </Row>
          <Col lg={12}>
            <DaysSessions loading={loading} updated={setUpdated} days={daysEventsArray} />
          </Col>
        <Row>
          {data.length !== 0 ? (
            <Pagination
              onChange={onChange}
              defaultCurrent={current}
              total={count}
              current={current}
            />
          ) : null}
        </Row>
      </div>
    </div>
  );
};

export default ErrorHandler(Sessions, axiosApiInstance);
