import React, { Component } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Checkbox } from "antd";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "../../css/leads.css";
const axiosApiInstance = axios.create();

class EditTrainer extends Component {
  state = {
    title: "",
    price: "",
    des: "",
    limit: "",
    selectedExType: "",
    selectedType: "",
    image: ""
  };
  getDetails = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/trainer_profile/${this.props.match.params.id}`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result);
      this.setState({
        fname: result.data.user.full_name,
        title: result.data.title,
        email: result.data.user.email,
        lname: result.data.user.last_name,
        mobile: result.data.user.mobile,
        facebook: result.data.fb,
        instagram: result.data.instgram,
        twitter: result.data.twitter,
        nutrition: result.data.nutrition,
        online: result.data.online,
        ground: result.data.onground,
        about: result.data.about,
      });
    }
  };
  componentDidMount() {
    this.getDetails();
  }

  handleChange = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };
  handleImage = (event)=> {
    event.preventDefault();
    let files = event.target.files;

    if(files && files.length > 0)
    {
       this.setState({image: files[0]});
    }
    else{
        console.log("no files selected");
    }
  }

  edit = async () => {
    let result
    let data = {
      title: this.state.title,
      full_name: this.state.fname,
      last_name: this.state.lname,
      email: this.state.email,
      about: this.state.about,
      fb: this.state.facebook,
      instgram: this.state.instagram,
      twitter: this.state.twitter,
      mobile: this.state.mobile,
      online: this.state.online,
      onground: this.state.ground,
      nutrition: this.state.nutrition,
      image: this.state.image
    };
    if (this.state.image !== "") {
      let formData = new FormData()

      for (let key in data) {

          formData.append(key, data[key])
      
      }
      result = await axiosApiInstance.put(
        APILINK + `/trainer_profile/${this.props.match.params.id}`,
        formData
      );

    }
    else {
      data = {
        title: this.state.title,
        full_name: this.state.fname,
        last_name: this.state.lname,
        email: this.state.email,
        about: this.state.about,
        fb: this.state.facebook,
        instgram: this.state.instagram,
        twitter: this.state.twitter,
        mobile: this.state.mobile,
        online: this.state.online,
        onground: this.state.ground,
        nutrition: this.state.nutrition,
      }
      result = await axiosApiInstance.put(
        APILINK + `/trainer_profile/${this.props.match.params.id}`,
        data
      );
    }

    console.log(data);

    console.log(result);
    if (result) {
      if (result.data.user) {
        setTimeout(() => {
          toast.info("Trainer info has been updated successfully");
        }, 500);
        setTimeout(() => {
          this.props.history.push(`/trainer/${this.props.match.params.id}`);
        }, 2000);
      }
    }
  };
  handleNutrition = (e) => {
    console.log(e.target.checked);
    this.setState({ nutrition: e.target.checked });
  };
  handleOnline = (e) => {
    console.log(e.target.checked);
    this.setState({ online: e.target.checked });
  };
  handleGround = (e) => {
    console.log(e.target.checked);
    this.setState({ ground: e.target.checked });
  };

  render() {
    return (
      <div className="page-header">
        <Container>
          <Form id="infoForm">
            <Row>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    name="title"
                    value={this.state.title}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Title"
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Full Name</Form.Label>
                  <Form.Control
                    name="fname"
                    value={this.state.fname}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Full Name"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Last Name</Form.Label>
                  <Form.Control
                    name="lname"
                    value={this.state.lname}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Last Name"
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Label>Email</Form.Label>

                <Form.Control
                  value={this.state.email}
                  onChange={this.handleChange}
                  placeholder="email"
                  name="email"
                  type="text"
                />
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Mobile</Form.Label>
                  <Form.Control
                    name="mobile"
                    value={this.state.mobile}
                    onChange={this.handleChange}
                    type="number"
                    placeholder="Mobile"
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Facebook</Form.Label>
                  <Form.Control
                    name="facebook"
                    value={this.state.facebook}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Facebook"
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Instagram</Form.Label>
                  <Form.Control
                    name="instagram"
                    value={this.state.instagram}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Instagram"
                  />
                </Form.Group>
              </Col>
              <Col sm={12} md={6}>
                <Form.Group controlId="exampleForm.ControlInput1">
                  <Form.Label>Twitter</Form.Label>
                  <Form.Control
                    name="twitter"
                    value={this.state.twitter}
                    onChange={this.handleChange}
                    type="text"
                    placeholder="Twitter"
                  />
                </Form.Group>
              </Col>
            </Row>

            <Row>
              <Col sm={12} md={6}>
                <Form.Label>About</Form.Label>

                <Form.Control
                  value={this.state.about}
                  onChange={this.handleChange}
                  placeholder="About"
                  name="about"
                  as="textarea"
                  rows={3}
                />
              </Col>
              <Col sm={12} md={6}>
                <Form.Label>Image</Form.Label>
                <Form.Control name="image" type="file" onChange={this.handleImage} />
              </Col>

            </Row>
            <Row>
              <Col className="mt-5" sm="12" md="6">
                <Checkbox
                  onChange={this.handleNutrition}
                  checked={this.state.nutrition}
                >
                  Nutrition
                </Checkbox>
                <Checkbox
                  onChange={this.handleGround}
                  checked={this.state.ground}
                >
                  On Ground{" "}
                </Checkbox>
                <Checkbox
                  onChange={this.handleOnline}
                  checked={this.state.online}
                >
                  Online
                </Checkbox>
              </Col>
            </Row>
            <Row>
              {" "}
              <Col sm={12}>
                <Button onClick={this.edit} className="submit-btn-add">
                  Edit
                </Button>
              </Col>
            </Row>
          </Form>
        </Container>
        <ToastContainer position="bottom-right" />
      </div>
    );
  }
}

export default ErrorHandler(EditTrainer, axiosApiInstance);
