import React, { useEffect, useCallback, useState } from "react";
import axios from "axios";
import { APILINK } from "../../EndPoint";
import ErrorHandler from "../../error-handler/ErrorHandler";
import {
  Container,
  Row,
  Col,
  Table,
  Modal,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { Checkbox } from "antd";
import DataTable from "react-data-table-component";
import "react-toastify/dist/ReactToastify.css";

const axiosApiInstance = axios.create();

const Trainer = (props) => {
  const [data, setData] = useState([]);

  const getTrainer = async () => {
    let result = await axiosApiInstance
      .get(APILINK + `/trainer_profile/${props.match.params.id}`)
      .catch((err) => {
        console.log(err);
      });
    if (result) {
      console.log(result.data);
      setData(result.data);
      
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    getTrainer();
  }, []);
  return (
    <div className="page-header">
      <Container>
        <div className="branch-details">
          <Row>
            <Col lg={12}>
              <img
                className="trainer-details-image"
                src={APILINK + data.image}
              />
            </Col>
          </Row>
          <Row>
            <Col lg={12}>
              {data.user ? (
                <ul>
                  <li>
                    <strong>Title : </strong>
                    {data.title}
                  </li>
                  <li>
                    <strong>Name : </strong>
                    {data.user.full_name + " " + data.user.last_name}
                  </li>
                  <li>
                    <strong>Email : </strong>
                    {data.user.email}
                  </li>
                  <li>
                    <strong>Phone : </strong>
                    {data.user.mobile}
                  </li>
                  <li>
                    <strong>Instagram : </strong>
                    {data.instgram}
                  </li>
                  <li>
                    <strong>Twitter : </strong>
                    {data.twitter}
                  </li>
                  <li>
                    <strong>FaceBook : </strong>
                    {data.fb}
                  </li>
                  <li>
                    <strong>WhatsApp : </strong>
                    {data.whtsapp}
                  </li>
                  <Checkbox checked={data.nutrition}>Nutrition</Checkbox>
                  <Checkbox checked={data.onground}>On Ground </Checkbox>
                  <Checkbox checked={data.online}>Online</Checkbox>
                  <li>
                    <strong>About the trainer : </strong>
                    {data.about}
                  </li>
                </ul>
              ) : null}
            </Col>
          </Row>
        </div>
      </Container>
    </div>
  );
};

export default ErrorHandler(Trainer, axiosApiInstance);
